import { action, makeObservable, observable, override } from "mobx";
import _ from "lodash-es";

import { TasksService } from "../../api/services/TasksService";
import { PlannedTask } from "../../api/models/PlannedTask";

// import { SecurityPostDto } from "../services/securityPost/dto/securityPostDto";

import { GuardPost, guardPostsService } from "entities";
import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "../../core/stores/BaseCrudStore";
import {
  IFiltersProps,
  IIdentifiable,
  IPaginatedProps,
} from "../../core/types";

interface IFilters extends IPaginatedProps, IFiltersProps {
  objectId: number;
}

export class PlannedTaskStore extends BaseCrudStore<
  PlannedTask,
  PlannedTask,
  PlannedTask,
  IFilters
> {
  @observable guardPosts: GuardPost[] = [];
  @observable isGuardPostsLoading: boolean = false;

  constructor() {
    super(
      new BaseApiService({
        create: TasksService.tasksPlannedCreate,
        get: TasksService.tasksPlannedRetrieve,
        getAll: TasksService.tasksPlannedList,
        update: TasksService.tasksPlannedPartialUpdate,
        delete: TasksService.tasksPlannedDestroy,
      })
    );

    makeObservable(this);
  }

  @action
  async getAllGuardPosts(objectId: IIdentifiable["id"]) {
    this.isGuardPostsLoading = true;
    const { results } = await guardPostsService.getAll!({
      object: objectId,
      limit: 99999999,
    });
    this.guardPosts = results || [];
    this.isGuardPostsLoading = false;
  }

  @override
  async create(createInput: PlannedTask) {
    const guardPosts = createInput.post || [];
    const taskEmployees = this.getTaskEmployees(guardPosts);
    createInput.task_employees = taskEmployees;
    return TasksService.tasksPlannedCreate({ requestBody: createInput });
  }

  @override
  async update(createInput: PlannedTask) {
    const guardPosts = createInput.post || [];
    const taskEmployees = this.getTaskEmployees(guardPosts);
    createInput.task_employees = taskEmployees;
    return TasksService.tasksPlannedPartialUpdate({
      id: createInput.id,
      requestBody: createInput,
    });
  }

  private getTaskEmployees(guardPosts: GuardPost["id"][]) {
    const result = _.flatten(
      guardPosts.map((guardPostId) => {
        const guardPost = this.guardPosts.find((gp) => gp.id === guardPostId);
        return guardPost?.post_employees.map((employee) => ({
          is_completed: false,
          employee: employee.employee,
        }));
      })
    );

    if (!this.entity?.task_employees) return result;

    return result.map((newTaskEmployee) => {
      const taskEmployee = this.entity?.task_employees.find(
        (taskEmployee) => taskEmployee.employee === newTaskEmployee?.employee
      );

      return {
        id: taskEmployee?.id,
        ...newTaskEmployee,
      };
    });
  }

  // @override
  // async update(updateInput: ): Promise<void> {
  //   const entity = await TasksService.tasksPlannedUpdate({
  //     id: updateInput.id,
  //     requestBody: updateInput,
  //   });
  //   this.entities
  //     .filter((x: PlannedTask) => x.id === entity.id)
  //     .map((x: PlannedTask) => {
  //       return (x = entity as PlannedTask);
  //     });
  // }
}

export type PlannedTaskStoreType = PlannedTaskStore;
