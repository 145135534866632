import { ChangeEventHandler } from "react";
import { Button, Col, Divider, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import {
  MapContainer,
  TileLayer,
  Popup,
  LayersControl,
  LayerGroup,
} from "react-leaflet";

import { L } from "modules/L18n";
import { DetailEvent, EventFile } from "modules/Events/Events.interfaces";
import ErrImg from "../../../assets/error.png";
import MarkerWithOpenedPopup from "components/MarkerWithOpenedPopup";
import EventFileComponent from "./EventFile";

const { BaseLayer } = LayersControl;

interface ISosProps {
  event: DetailEvent;
  sosComment: string;
  workEndDateSubmitting: boolean;
  workStartDateSubmitting: boolean;
  handleSosChange: ChangeEventHandler;
  handleWorkStartDate: () => void;
  handleWorkEndDate: () => void;
}

const SosEvent = ({
  event,
  sosComment,
  workEndDateSubmitting,
  workStartDateSubmitting,
  handleSosChange,
  handleWorkStartDate,
  handleWorkEndDate,
}: ISosProps) => {
  const comment =
    event!.comments && event!.comments.length ? event!.comments[0] : null;

  return (
    <>
      <Row>
        <Col span={15}>
          <Row>
            <Col span={12}>{L("EVENTS_MODAL_SECURITYPOST_LABEL")}:</Col>
            <Col>{event.post_name}</Col>
            <Col></Col>
          </Row>
          <Row>
            <Col span={12}>{L("EVENTS_MODAL_EMPLOYEE_LABEL")}:</Col>
            <Col>{event.employee_name}</Col>
          </Row>
          <Row>
            <Col span={12}>{L("EVENTS_MODAL_TIME_LABEL")}:</Col>
            <Col>
              {moment(event.creation_date).format("DD.MM.YYYY HH:mm:ss")}
            </Col>
          </Row>
          <Row>
            <Col span={12}>{L("EVENTS_MODAL_WORKSTARTDATE_LABEL")}:</Col>
            <Col>
              {event.work_start_date
                ? moment(event.work_start_date).format("DD.MM.YYYY HH:mm:ss")
                : ""}
            </Col>
          </Row>
          <Row>
            <Col span={12}>{L("EVENTS_MODAL_WORKENDDATE_LABEL")}:</Col>
            <Col>
              {event.work_end_date
                ? moment(event.work_end_date).format("DD.MM.YYYY HH:mm:ss")
                : ""}
            </Col>
          </Row>
        </Col>
        <Col offset={4}>
          <div style={{ textAlign: "right" }}>
            <img src={ErrImg} alt="" />
          </div>
        </Col>
      </Row>

      <Divider />

      {!!event.files.length && (
        <>
          {event.files.map((file: EventFile) => (
            <EventFileComponent key={file.id} file={file} />
          ))}
          
          <Divider />
        </>
      )}

      {!event.work_end_date && (
        <Row>
          <TextArea onChange={handleSosChange} value={sosComment} />
          <Row style={{ marginTop: 10 }}>
            <Button
              type={"primary"}
              disabled={!!event.work_start_date}
              onClick={handleWorkStartDate}
              loading={workStartDateSubmitting}
            >
              {L("EVENTS_MODAL_STARTWORK_BUTTON")}
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              type={"primary"}
              disabled={!event.work_start_date}
              loading={workEndDateSubmitting}
              onClick={handleWorkEndDate}
            >
              {L("EVENTS_MODAL_ENDWORK_BUTTON")}
            </Button>
          </Row>
        </Row>
      )}

      {event.work_end_date && comment && (
        <div>
          <Row>
            <Col span={18}>{comment.employee_name}:</Col>
            <Col span={6}>
              {moment(comment.creation_time).format("DD.MM.YYYY HH:mm")}
            </Col>
          </Row>
          <Row style={{ fontWeight: "bolder" }}>{comment.text}</Row>
        </div>
      )}

      <Divider />

      <Row>
        <MapContainer
          style={{ height: 300, width: "100%" }}
          maxZoom={12}
          center={{ lat: +event.latitude!, lng: +event.longitude! }}
          zoom={14}
        >
          <LayersControl position="topright">
            <BaseLayer checked name="Схема">
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </BaseLayer>
            <BaseLayer checked name="Спутник">
              <TileLayer
                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                subdomains={["mt0", "mt1", "mt2", "mt3"]}
                id="google"
                maxZoom={12}
              />
            </BaseLayer>

            <LayerGroup>
              <MarkerWithOpenedPopup
                position={{ lat: +event.latitude!, lng: +event.longitude! }}
              >
                <Popup
                  closeButton={false}
                  autoPan={false}
                  closeOnClick={false}
                  autoClose={false}
                >
                  {event.employee_name}
                </Popup>
              </MarkerWithOpenedPopup>
            </LayerGroup>
          </LayersControl>
        </MapContainer>
      </Row>
    </>
  );
};

export default SosEvent;
