import { Employees } from "api/models/Employees";

export const getEmployeeFullName = ({
  username,
  first_name,
  last_name,
  surname,
}: Pick<Employees, "username" | "first_name" | "last_name" | "surname">) =>
  `${last_name} ${first_name ? first_name[0] + "." : ""} ${
    surname ? surname[0] + "." : ""
  } (${username})`;
