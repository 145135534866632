/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMsg } from '../models/CreateMsg';
import type { EmployeesList } from '../models/EmployeesList';
import type { Msg } from '../models/Msg';
import type { MsgUnreadCount } from '../models/MsgUnreadCount';
import type { PaginatedMsgList } from '../models/PaginatedMsgList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MsgService {

    /**
     * @returns PaginatedMsgList
     * @throws ApiError
     */
    public static msgList({
        consumer,
        limit,
        offset,
        ordering,
        search,
        unread,
    }: {
        consumer?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
        unread?: boolean,
    }): CancelablePromise<PaginatedMsgList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/msg/',
            query: {
                'consumer': consumer,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
                'unread': unread,
            },
        });
    }

    /**
     * @returns Msg
     * @throws ApiError
     */
    public static msgRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Msg> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/msg/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Msg
     * @throws ApiError
     */
    public static msgUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Msg,
    }): CancelablePromise<Msg> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/msg/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static msgDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/msg/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Прочитать сообщение
     * Добавляет текущего пользователя в readers
     * @returns any No response body
     * @throws ApiError
     */
    public static msgReadCreate({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/msg/{id}/read',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Прочитать сообщения пачкой
     * Добавляет текущего пользователя в readers для каждого сообщения
     * из списка messages в request body
     * @returns Msg
     * @throws ApiError
     */
    public static msgBulkReadCreate({
        requestBody,
    }: {
        requestBody: Msg,
    }): CancelablePromise<Msg> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/msg/bulk_read',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * С МЦ отправлять с consumer и object_id, с МП отправлять с object_id
     * @returns Msg
     * @throws ApiError
     */
    public static msgCreateCreate({
        requestBody,
    }: {
        requestBody: CreateMsg,
    }): CancelablePromise<Msg> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/msg/create/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить пользователей, от которых у текущего есть непрочитанные сообщения
     * @returns EmployeesList
     * @throws ApiError
     */
    public static msgGetUnreadEmployeesRetrieve(): CancelablePromise<EmployeesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/msg/get_unread_employees',
        });
    }

    /**
     * Кол-во непрочитанных сообщений
     * @returns MsgUnreadCount
     * @throws ApiError
     */
    public static msgUnreadCountRetrieve(): CancelablePromise<MsgUnreadCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/msg/unread-count',
        });
    }

}
