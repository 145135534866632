import { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { Button, Divider, Layout, List, Row } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";

import EventListItem from "./EventListItem";
import { L } from "modules/L18n";
import { useStores } from "store";
import { EventTypeEnum } from "entities/Events";
import EventsFilter from "modules/Events/components/EventsFilter";
import { catchFlowCancel } from "utils";

const { Sider } = Layout;

interface IEventsSiderProps {
  open: boolean;
  onClose: () => void;
}

const EventsSider = ({ open, onClose }: IEventsSiderProps) => {
  const { eventStore, objectStore } = useStores();

  const infinityScrollRef = useRef<InfiniteScroll>(null);

  useEffect(() => {
    eventStore.setOffset(0);
    eventStore.setLimit(30);
    eventStore.setFilters(
      {
        ordering: "-creation_date",
        types: [EventTypeEnum.REPORT, EventTypeEnum.ALARM],
        object: eventStore.showAllObjects
          ? undefined
          : objectStore.selectedObject?.id,
      },
      true
    );
  }, [eventStore, objectStore.selectedObject?.id]);

  useEffect(() => {
    infinityScrollRef.current?.el.scrollTo(0, 0);
    return catchFlowCancel(eventStore.loadMore(true)).cancel;
  }, [eventStore, eventStore.filters]);

  if (!open) {
    return null;
  }

  return (
    <>
      <Sider
        width={290}
        style={{
          maxHeight: "calc(100vh - 65px)",
          marginTop: 1,
          backgroundColor: "white",
          padding: 10,
        }}
      >
        <Row justify={"space-between"} align={"middle"}>
          <div className="ant-modal-title">{L("EVENTS_TITLE")}</div>
          <Button
            type={eventStore.isFilterApplied ? "primary" : undefined}
            icon={<SearchOutlined />}
            onClick={() => eventStore.showEventFilter()}
          >
            {L("SHOW_FILTER")}
          </Button>
          <CloseOutlined onClick={onClose} />
        </Row>

        <Divider style={{ margin: "10px 0px 0px 0px", height: 2 }} />

        <InfiniteScroll
          ref={infinityScrollRef}
          next={eventStore.loadMore}
          hasMore={eventStore.hasMore}
          dataLength={eventStore.entities.length}
          loader={null}
          height="calc(100vh - 120px)"
        >
          <List
            rowKey="id"
            loading={eventStore.isEntitiesLoading}
            itemLayout="horizontal"
            dataSource={eventStore.entities}
            renderItem={(event) => (
              <EventListItem event={event} onClick={eventStore.show} />
            )}
          />
        </InfiniteScroll>
      </Sider>

      {eventStore.isEventsFilterOpen && <EventsFilter />}
    </>
  );
};

export default observer(EventsSider);
