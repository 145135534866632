import {
  BaseDeletePoint,
  BaseGetAllPoint,
  BaseGetPoint,
  BasePostPoint,
  BaseUpdatePoint,
  IIdentifiable,
} from "core/types";

export class BaseServiceMock<T extends IIdentifiable> {
  private entities: Record<IIdentifiable["id"], T> = [];

  public get: BaseGetPoint<T> = async ({ id }) => this.entities[id];

  public getAll: BaseGetAllPoint<T> = async () => ({
    results: Object.values(this.entities),
    next: null,
    previous: null,
  });

  public post: BasePostPoint<T> = async ({ requestBody }) => {
    this.entities[requestBody.id] = requestBody;
    return this.entities[requestBody.id];
  };

  public put: BaseUpdatePoint<T> = async ({ id, requestBody }) => {
    this.entities[id] = requestBody;
    return this.entities[id];
  };

  public delete: BaseDeletePoint = async ({ id }) => {
    delete this.entities[id];
  };
}
