/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Coords } from '../models/Coords';
import type { PaginatedCoordsList } from '../models/PaginatedCoordsList';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CoordsService {

    /**
     * @returns PaginatedCoordsList
     * @throws ApiError
     */
    public static coordsList({
        dateGte,
        dateLte,
        employee,
        limit,
        offset,
        ordering,
        search,
    }: {
        dateGte: string,
        dateLte: string,
        employee: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedCoordsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/coords/',
            query: {
                'date_gte': dateGte,
                'date_lte': dateLte,
                'employee': employee,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns Coords
     * @throws ApiError
     */
    public static coordsCreate({
        requestBody,
    }: {
        requestBody: Coords,
    }): CancelablePromise<Coords> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/coords/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Coords
     * @throws ApiError
     */
    public static coordsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Coords> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/coords/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Coords
     * @throws ApiError
     */
    public static coordsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Coords,
    }): CancelablePromise<Coords> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/coords/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static coordsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/coords/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
