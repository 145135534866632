import { BaseServiceMock } from "../../core/services/BaseServiceMock";
import { HeadOfSecurity } from "./HeadOfSecurity.interfaces";

class HeadOfSecurityServiceMock {
  private baseServiceMock = new BaseServiceMock<HeadOfSecurity>();

  public headOfSecurityRetrieve = this.baseServiceMock.get;
  public headOfSecurityCreate = this.baseServiceMock.post;
  public headOfSecurityList = this.baseServiceMock.getAll;
  public headOfSecurityUpdate = this.baseServiceMock.put;
  public headOfSecurityDestroy = this.baseServiceMock.delete;
}

export const headOfSecurityServiceMock = new HeadOfSecurityServiceMock();
