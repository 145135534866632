import { action, makeObservable, observable, override } from "mobx";

import { EmployeesService, MonitoringCenterEmployees } from "api";
import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import {
  EditRegionalEmployees,
  NewRegionalEmployees,
} from "./RegionalEmployees.interfaces";
import { Roles } from "entities/MCEmployees";

interface IFilters extends IFiltersProps, IPaginatedProps {
  objectId?: IIdentifiable["id"];
}

export class RegionalEmployeesStore extends BaseCrudStore<
  MonitoringCenterEmployees,
  NewRegionalEmployees,
  EditRegionalEmployees,
  IFilters
> {
  @observable isRolesLoading: boolean = false;
  @observable roles: Roles = [];

  constructor() {
    super(
      new BaseApiService({
        create: EmployeesService.employeesRegionalEmployeeCreateCreate,
        update: EmployeesService.employeesRegionalEmployeeUpdate,
        delete: EmployeesService.employeesRegionalEmployeeDestroy,
      })
    );

    makeObservable(this);
  }

  @action
  async getRoles() {
    this.isRolesLoading = true;
    let result = await EmployeesService.employeesRolesRetrieve();
    this.roles = result.roles;
    this.isRolesLoading = false;
  }

  @override
  async createEntity(input: Partial<NewRegionalEmployees>) {
    const entity: NewRegionalEmployees = {
      username: "",
      first_name: "",
      surname: "",
      address: "",
      // isActive: false,
      role: undefined,
      password: "",
      admin_objs: [],
      ...input,
      id: 0,
    };

    this.entity = entity as unknown as MonitoringCenterEmployees;

    this.roles = [];
  }

  async changeLanguage(lng: string) {
    // FIXME:
    throw new Error("Language change is not implemented");
  }
}

export type RegionalEmployeesStoreType = RegionalEmployeesStore;
