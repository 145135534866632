import { Input, Form, Checkbox } from "antd";
import moment from "moment";
import _ from "lodash-es";

import { L } from "modules/L18n";
import {
  Contractors,
  ContractorsStore,
  Contracts,
  ContractStore,
  DetailObject,
  Employees,
  EmployeeStore,
  getEmployeeFullName,
  ObjectGroupStore,
} from "entities";
import SelectWithStore from "scenes/GuardedObjects/Contracts/elements/SelectWithStore";
import { _RoleTypeEnum } from "entities/Employees/Employees.interfaces";

import rules from "../CreateOrUpdateObject.validation";

const FORM_ITEM_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 15 },
};

const employeeStore = new EmployeeStore();
const objectGroupStore = new ObjectGroupStore();
const contractStore = new ContractStore();
const contractorsStore = new ContractorsStore();
const inspectorsStore = new EmployeeStore();

inspectorsStore.setFilters({ roles: [_RoleTypeEnum.REGIONAL_REPRESENTATIVE] });

interface IObjectsGeneralTabProps {
  obj: DetailObject | null;
}

const ObjectsGeneralTab = ({ obj }: IObjectsGeneralTabProps) => {
  return (
    <>
      <Form.Item
        name="name"
        label={L("OBJECT_MODAL_NAME_LABEL")}
        rules={rules.name}
        {...FORM_ITEM_LAYOUT}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="act_print_name"
        label={L("GUARDED_OBJECT_MODAL_ACTPRINTNAME_LABEL")}
        {...FORM_ITEM_LAYOUT}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="address"
        label={L("OBJECT_MODAL_ADDRESS_LABEL")}
        rules={rules.name}
        {...FORM_ITEM_LAYOUT}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone_number"
        label={L("OBJECT_MODAL_PHONENUMBER_LABEL")}
        rules={rules.name}
        {...FORM_ITEM_LAYOUT}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="head_of_security_id"
        label={L("OBJECT_MODAL_HEADOFSECURITY_LABEL")}
        rules={rules.headOfSecurityId}
        {...FORM_ITEM_LAYOUT}
      >
        <SelectWithStore
          store={employeeStore}
          initialValue={obj?.head_of_security_id as Employees}
          getOption={(hos) => ({
            label: getEmployeeFullName(hos),
            value: hos.id,
          })}
        />
      </Form.Item>

      <Form.Item
        name="group"
        label={L("OBJECT_MODAL_GROUP_LABEL")}
        rules={rules.objectGroupId}
        {...FORM_ITEM_LAYOUT}
      >
        <SelectWithStore store={objectGroupStore} initialValue={obj?.group} />
      </Form.Item>

      <Form.Item
        name="contract"
        label={L("GUARDED_OBJECT_MODAL_CONTRACT_LABEL")}
        {...FORM_ITEM_LAYOUT}
      >
        <SelectWithStore
          store={contractStore}
          initialValue={obj?.contract as unknown as Contracts}
          getOption={(contract) => ({
            value: contract.id,
            label: `№${contract.number} ${L("COMMON_DOC_DATE")} ${moment(
              contract.date
            ).format("DD.MM.YYYY")}`,
          })}
        />
      </Form.Item>

      <Form.Item
        name="so_executor"
        label={L("GUARDED_OBJECT_MODAL_CONTRACT_EXECUTOR_LABEL")}
        {...FORM_ITEM_LAYOUT}
      >
        <SelectWithStore
          store={contractorsStore}
          initialValue={obj?.so_executor as unknown as Contractors}
        />
      </Form.Item>

      <Form.Item
        name="regional_representative"
        label={L("GUARDED_OBJECT_MODAL_EMPLOYEES_LABEL")}
        {...FORM_ITEM_LAYOUT}
      >
        <SelectWithStore
          mode="multiple"
          store={inspectorsStore}
          initialValue={obj?.regional_representative}
          getOption={(hos) => ({
            label: getEmployeeFullName(hos),
            value: hos.id,
          })}
        />
      </Form.Item>

      <Form.Item
        name="show_speed"
        label={L("OBJECT_MODAL_SHOWSPEED_LABEL")}
        valuePropName="checked"
        initialValue={false}
        {...FORM_ITEM_LAYOUT}
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        name="is_guarded"
        label={L("IS_GUARDED")}
        valuePropName="checked"
        initialValue={false}
        {...FORM_ITEM_LAYOUT}
      >
        <Checkbox />
      </Form.Item>
    </>
  );
};

export default ObjectsGeneralTab;
