import { useEffect, useMemo, useRef } from "react";
import _ from "lodash-es";

export const useDebounced = (fn: (...props: any) => any, delay: number) => {
  const fnRef = useRef(fn);

  const debounced = useMemo(
    () =>
      _.debounce(fnRef.current, delay, {
        leading: false,
        trailing: true,
      }),
    [delay]
  );

  useEffect(() => {
    return () => {
      debounced.cancel();
    };
  }, [debounced]);

  return debounced;
};
