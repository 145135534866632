import { IFiltersProps, IPaginatedProps } from "core/types";
import { BaseApiService } from "core/services/BaseApiService";
import { ObjectsGroups } from "./ObjectsGroup.interfaces";
import { ObjectsService } from "../../api/services/ObjectsService";
import { BaseCrudStore } from "../../core/stores/BaseCrudStore";

interface IFilters extends IFiltersProps, IPaginatedProps {}

export class ObjectGroupStore extends BaseCrudStore<
  ObjectsGroups,
  ObjectsGroups,
  ObjectsGroups,
  IFilters
> {
  constructor() {
    super(
      new BaseApiService({
        delete: ObjectsService.objectsGroupsDestroy,
        get: ObjectsService.objectsGroupsRetrieve,
        getAll: ObjectsService.objectsGroupsList,
        update: ObjectsService.objectsGroupsUpdate,
        create: ObjectsService.objectsGroupsCreate,
      })
    );
  }
}

export type ObjectGroupStoreType = ObjectGroupStore;
