import { makeObservable} from "mobx";

import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { GuardPost } from "./GuardPosts.interfaces";
import { guardPostsService} from "./GuardPosts.sevices";

export class GuardPostStore extends BaseCrudStore<GuardPost > {
  constructor() {
    super(
      new BaseApiService(guardPostsService)
    );

    makeObservable(this);
  }
}

export type GuardPostStoreType = GuardPostStore;
