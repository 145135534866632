import { observable, action, makeObservable, override } from "mobx";

import {
  ContractsService,
  Contractors,
  EmployeeCategory,
  UpdateContracts,
} from "api";
import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { Contracts, CreateContracts } from "./Contracts.interfaces";

interface IFilters extends IFiltersProps, IPaginatedProps {
  object?: IIdentifiable["id"];
}

export class ContractStore extends BaseCrudStore<
  Contracts,
  CreateContracts,
  UpdateContracts,
  IFilters
> {
  constructor() {
    super(
      new BaseApiService({
        create: ContractsService.contractsCreate,
        get: ContractsService.contractsRetrieve,
        getAll: ContractsService.contractsList,
        update: ContractsService.contractsUpdate,
        delete: ContractsService.contractsDestroy,
      })
    );

    makeObservable(this);
  }

  @observable employeeCategories: EmployeeCategory[] | null = null;
  @observable isEmployeeCategoriesLoading: boolean = false;

  @action
  async loadEmployeeCategories() {
    this.isEmployeeCategoriesLoading = true;
    let { results } = await ContractsService.contractsEmployeeCategoryList({
      limit: 100,
    });
    this.employeeCategories = results || [];
    this.isEmployeeCategoriesLoading = false;
  }

  @override
  async createEntity() {
    let newItem = { id: 0 } as CreateContracts;
    newItem.contract_employees = [];

    if (this.employeeCategories === null) {
      await this.loadEmployeeCategories();
    }

    this.employeeCategories!.forEach((category) => {
      newItem.contract_employees.push({
        count: 0,
        category: category.id,
      });
    });
    this.entity = newItem as unknown as Contracts;
  }
}

export type ContractStoreType = ContractStore;
