/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedReportList } from '../models/PaginatedReportList';
import type { Report } from '../models/Report';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportsService {

    /**
     * Получить список отчетов
     * @returns PaginatedReportList
     * @throws ApiError
     */
    public static reportsList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedReportList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Создать отчет
     * @returns Report
     * @throws ApiError
     */
    public static reportsCreate({
        requestBody,
    }: {
        requestBody: Report,
    }): CancelablePromise<Report> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/reports/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить отчет в формате xlsx
     * Генерирует отчет в формате xlsx
     * @returns any No response body
     * @throws ApiError
     */
    public static reportsXlsxRetrieve({
        allReports = true,
        endDate,
        lastPerObject,
        startDate,
    }: {
        /**
         * Все отчеты объекта
         */
        allReports?: boolean,
        /**
         * format: ISO 8601
         */
        endDate?: string,
        /**
         * Последний отчет по каждому объекту
         */
        lastPerObject?: boolean,
        startDate?: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/reports/xlsx',
            query: {
                'all_reports': allReports,
                'end_date': endDate,
                'last_per_object': lastPerObject,
                'start_date': startDate,
            },
        });
    }

}
