import { Avatar, List, Row } from "antd";
import { BellTwoTone, CameraFilled, WarningTwoTone } from "@ant-design/icons";
import moment from "moment";

import { Event, EventTypeEnum } from "entities";

import videoCameraIcon from "./images/video-camera.png";

interface EventListItemProps {
  event: Event;
  onClick: (eventId: Event["id"]) => void;
}

const EventListItem = ({ event, onClick }: EventListItemProps) => {
  const isAlarm = event.event_type === EventTypeEnum.ALARM;
  const AvatarIcon =
    isAlarm ? WarningTwoTone : BellTwoTone;

  return (
    <List.Item
      style={{
        cursor: "pointer",
        backgroundColor: event.is_processed ? "white" : "lightblue",
        paddingLeft: 10,
      }}
      onClick={() => onClick(event.id)}
      actions={
        event.has_video
          ? [<img alt={""} style={{ width: "100%" }} src={videoCameraIcon} />]
          : event.has_photo
          ? [<CameraFilled />]
          : []
      }
    >
      <List.Item.Meta
        avatar={
          <Avatar
            style={{ backgroundColor: "white" }}
            icon={
              <AvatarIcon
                twoToneColor={isAlarm ? "red" : "orange"}
              />
            }
          />
        }
        title={moment(event.creation_date).format("DD.MM.YYYY HH:mm")}
        description={
          <>
            {event.text && <Row>{event.text}</Row>}
            <Row>{event.employee_name}</Row>
          </>
        }
      />
    </List.Item>
  );
};

export default EventListItem;
