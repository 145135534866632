import { action, makeObservable, observable } from "mobx";
import { Moment } from "moment-timezone";

import { Employees } from "entities";
import { CoordsService, EmployeesService } from "api";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { Coords } from "./Coordinates.interfaces";
import { coordsService } from "./Coordinates.service";

export class CoordinatesStore extends BaseCrudStore<
  Coords,
  Coords,
  Coords,
  Parameters<typeof CoordsService.coordsList>[0]
> {
  @observable employees!: Employees[];
  @observable isEmployeesLoading: boolean = false;

  constructor() {
    super(coordsService);
    this.filters = { ordering: "time" };
    makeObservable(this);
  }

  @action
  async loadEmployees(objectId: number, date: Moment) {
    this.isEmployeesLoading = true;
    let { results } = await EmployeesService.employeesList({ objectId, limit: 9999999 });
    this.employees = results || [];
    this.isEmployeesLoading = false;
    return results;
  }

  @action
  resetTracks() {
    this.entities = [];
  }
}

export type CoordinatesStoreType = CoordinatesStore;
