/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Logs } from '../models/Logs';
import type { MobileLoginLogs } from '../models/MobileLoginLogs';
import type { MobileLoginLogsMain } from '../models/MobileLoginLogsMain';
import type { PaginatedLogsList } from '../models/PaginatedLogsList';
import type { PaginatedMobileLoginLogsMainList } from '../models/PaginatedMobileLoginLogsMainList';
import type { PatchedLogs } from '../models/PatchedLogs';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LogsService {

    /**
     * @returns PaginatedLogsList
     * @throws ApiError
     */
    public static logsList({
        dateGte,
        dateLte,
        employee,
        limit,
        object,
        offset,
        ordering,
        postName,
        search,
    }: {
        dateGte?: string,
        dateLte?: string,
        employee?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        object?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        postName?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedLogsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/logs/',
            query: {
                'date_gte': dateGte,
                'date_lte': dateLte,
                'employee': employee,
                'limit': limit,
                'object': object,
                'offset': offset,
                'ordering': ordering,
                'post_name': postName,
                'search': search,
            },
        });
    }

    /**
     * @returns Logs
     * @throws ApiError
     */
    public static logsCreate({
        requestBody,
    }: {
        requestBody: Logs,
    }): CancelablePromise<Logs> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/logs/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Logs
     * @throws ApiError
     */
    public static logsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Logs> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/logs/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Logs
     * @throws ApiError
     */
    public static logsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Logs,
    }): CancelablePromise<Logs> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/logs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Logs
     * @throws ApiError
     */
    public static logsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedLogs,
    }): CancelablePromise<Logs> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/logs/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static logsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/logs/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Получить логи (доступна филтрация)
     * @returns PaginatedMobileLoginLogsMainList
     * @throws ApiError
     */
    public static logsMobileLoginLogsList({
        dateGte,
        dateLte,
        deviceName,
        employee,
        limit,
        offset,
        ordering,
        search,
    }: {
        dateGte?: string,
        dateLte?: string,
        /**
         * Модель устройства
         */
        deviceName?: string,
        /**
         * Сотрудник
         */
        employee?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedMobileLoginLogsMainList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/logs/mobile_login_logs',
            query: {
                'date_gte': dateGte,
                'date_lte': dateLte,
                'device_name': deviceName,
                'employee': employee,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Создать лог пользователя
     * @returns MobileLoginLogs
     * @throws ApiError
     */
    public static logsMobileLoginLogsCreate({
        requestBody,
    }: {
        requestBody: MobileLoginLogs,
    }): CancelablePromise<MobileLoginLogs> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/logs/mobile_login_logs',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить лог по ID
     * @returns MobileLoginLogsMain
     * @throws ApiError
     */
    public static logsMobileLoginLogsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<MobileLoginLogsMain> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/logs/mobile_login_logs/{id}',
            path: {
                'id': id,
            },
        });
    }

}
