import { action, makeObservable, observable } from "mobx";
import moment from "moment-timezone";

import { ReportsService } from "api";
import { downloadFile } from "utils/AsyncDownloadFile";
import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { Report } from "./DailyReports.interfaces";

export class DailyReportsStore extends BaseCrudStore<Report> {
  @observable isDailyReportLoading: boolean = false;

  constructor() {
    super(new BaseApiService({}));

    makeObservable(this);
  }

  @action
  async loadDailyReport(to: moment.Moment, from: moment.Moment) {
    this.isDailyReportLoading = true;

    const file = await ReportsService.reportsXlsxRetrieve({
      endDate: to.toISOString(true),
      startDate: from.toISOString(true),
    });

    let { name } = file;

    if (!name) {
      name = `DailyReport_${from.format("DD.MM.YYYY")}_${to.format(
        "DD.MM.YYYY"
      )}.xlsx`;
    }

    downloadFile(file, name);

    this.isDailyReportLoading = false;
  }
}

export type DailyReportsStoreType = DailyReportsStore;
