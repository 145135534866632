import { Suspense, useCallback, useMemo } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Layout } from "antd";
import { Helmet } from "react-helmet-async";
import * as Sentry from "@sentry/react";

import { useStores } from "store";
import Spin from "components/Spin";
import Sider from "scenes/Objects/Sider";
import "./AppLayout.styles.less";
import Header from "./elements/Header/Header";
import { routers, usePageTitle, getMenuItemsFromRoutes } from "../Router";

const AppLayout = () => {
  const { authenticationStore } = useStores();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const pageTitle = usePageTitle();

  const { isAuthenticated, isInitialized } = authenticationStore;

  const menuItems = useMemo(
    () => getMenuItemsFromRoutes(routers),
    [authenticationStore.user]
  );

  const showSider =
    pathname.startsWith("/objects") ||
    (pathname.startsWith("/reports") && !pathname.startsWith("/reports/act"));

  const onMenuSelect = useCallback(
    ({ key }: { key: string }) => navigate(key),
    [navigate]
  );

  if (!isInitialized) {
    return null;
  }

  if (!isAuthenticated) {
    return <Navigate to="/user/login" />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Layout className="app-layout" style={{ height: "100vh" }}>
        <Layout.Header
          className={"iguard-header"}
          style={{ minHeight: 52, padding: 0, height: 65, zIndex: 9 }}
        >
          <Header
            items={menuItems}
            pathname={pathname}
            onMenuSelect={onMenuSelect}
          />
        </Layout.Header>
        <Layout>
          <Sider show={showSider} />
          <Layout.Content style={{ overflowY: "auto" }}>
            <Suspense fallback={<Spin />}>
              <Sentry.ErrorBoundary
                fallback={({ error, componentStack }) => (
                  <>
                    <div>{error.toString()}</div>
                    <div>{componentStack}</div>
                  </>
                )}
              >
                <Outlet />
              </Sentry.ErrorBoundary>
            </Suspense>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  );
};

export default observer(AppLayout);
