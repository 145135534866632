import { RefreshToken, RememberMe } from "./Auth.interfaces";

import {
  REFRESH_TOKEN_LOCAL_STORAGE,
  REMEMBER_ME_LOCAL_STORAGE,
} from "./Auth.literals";

const getStorage = (rememberMe?: RememberMe) => {
  if (typeof rememberMe !== "boolean") {
    const storagedRememberMe =
      localStorage.getItem(REMEMBER_ME_LOCAL_STORAGE) ||
      sessionStorage.getItem(REMEMBER_ME_LOCAL_STORAGE);

    rememberMe = storagedRememberMe
      ? Boolean(Number(storagedRememberMe))
      : undefined;
  }

  if (typeof rememberMe !== "boolean") return null;

  return rememberMe ? localStorage : sessionStorage;
};

export const getRefreshTokenFromStorage = (): RefreshToken | null => {
  const storage = getStorage();

  if (!storage) {
    return null;
  }

  const refresh = storage.getItem(REFRESH_TOKEN_LOCAL_STORAGE);

  return refresh;
};

export const getRememberMeFromStorage = (): RememberMe | null => {
  const storage = getStorage();

  if (!storage) {
    return null;
  }

  const rememberMePlain = storage.getItem(REMEMBER_ME_LOCAL_STORAGE);

  if (rememberMePlain === null) {
    return null;
  }

  const rememberMe = rememberMePlain === "1";

  return rememberMe;
};

export const setRefreshToken = (
  refreshToken: RefreshToken,
  rememberMe: RememberMe
) => {
  const storage = getStorage(rememberMe);

  if (!storage) return;

  storage.setItem(REFRESH_TOKEN_LOCAL_STORAGE, refreshToken);
  storage.setItem(REMEMBER_ME_LOCAL_STORAGE, rememberMe ? "1" : "0");
};

export const clearRefreshToken = () => {
  const storage = getStorage();

  if (!storage) return;

  storage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE);
  storage.removeItem(REMEMBER_ME_LOCAL_STORAGE);
};
