import { action, makeObservable, observable, override } from "mobx";

import { MsgService } from "api";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { BaseApiService } from "core/services/BaseApiService";
import { eventService, EventTypeEnum } from "modules/Events";
import { CreateMsg, Msg } from "./Messages.interfaces";

interface MessagesStoreFilters extends IFiltersProps, IPaginatedProps {
  consumer?: IIdentifiable["id"];
}

export class MessagesStore extends BaseCrudStore<
  Msg,
  CreateMsg,
  Msg,
  MessagesStoreFilters
> {
  @observable private _consumerId: number | null = null;

  constructor() {
    super(
      new BaseApiService({
        get: MsgService.msgRetrieve,
        getAll: MsgService.msgList,
        create: MsgService.msgCreateCreate,
        update: MsgService.msgUpdate,
        delete: MsgService.msgDestroy,
      })
    );

    this.filters.ordering = "-creation_time";

    makeObservable(this);
  }

  set consumerId(value: number | null) {
    this._consumerId = value;
  }

  get consumerId(): number | null {
    return this._consumerId;
  }

  @override
  get = async (id: number) => {
    const msg = await super.get(id);
    this.readAllMessages();
    return msg;
  };

  @action
  sendMessage = async (newMsg: CreateMsg) => {
    const msg = await super.create(newMsg);
    this.entities = [msg, ...this.entities];
  };

  @action
  receiveMessage = (newMsg: any) => {
    if (newMsg.creators.includes(this._consumerId)) {
      this.entities = [newMsg, ...this.entities];
    }
  };

  @action
  readAllMessages = async () => {
    const messages = this.entities
      .filter((message) => !message.readers?.includes(this._consumerId))
      .map((message) => message.id);

    if (messages.length === 0) {
      return;
    }

    const result = await MsgService.msgBulkReadCreate({
      requestBody: { messages },
    });

    await this.getUnreadedEmployeeIds();

    return result;
  };

  @observable unreadedEmployeeIds: number[] = [];

  @action
  getUnreadedEmployeeIds = async () => {
    const { employees } = await MsgService.msgGetUnreadEmployeesRetrieve();
    this.unreadedEmployeeIds = employees;
  };
}

export type MessagesStoreType = MessagesStore;
