/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetailObject } from '../models/DetailObject';
import type { GuardedObjects } from '../models/GuardedObjects';
import type { Objects } from '../models/Objects';
import type { ObjectsGroups } from '../models/ObjectsGroups';
import type { PaginatedGuardedObjectsList } from '../models/PaginatedGuardedObjectsList';
import type { PaginatedObjectsGroupsList } from '../models/PaginatedObjectsGroupsList';
import type { PaginatedObjectsList } from '../models/PaginatedObjectsList';
import type { PaginatedShortObjectsList } from '../models/PaginatedShortObjectsList';
import type { PatchedGuardedObjects } from '../models/PatchedGuardedObjects';
import type { PatchedObjects } from '../models/PatchedObjects';
import type { PatchedObjectsGroups } from '../models/PatchedObjectsGroups';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ObjectsService {

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns PaginatedObjectsList
     * @throws ApiError
     */
    public static objectsList({
        isGuarded,
        isOnline,
        limit,
        offset,
        ordering,
        search,
    }: {
        isGuarded?: boolean,
        isOnline?: boolean,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedObjectsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects',
            query: {
                'is_guarded': isGuarded,
                'is_online': isOnline,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns Objects
     * @throws ApiError
     */
    public static objectsCreate({
        requestBody,
    }: {
        requestBody: Objects,
    }): CancelablePromise<Objects> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/objects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns DetailObject
     * @throws ApiError
     */
    public static objectsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<DetailObject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns Objects
     * @throws ApiError
     */
    public static objectsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Objects,
    }): CancelablePromise<Objects> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/objects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns Objects
     * @throws ApiError
     */
    public static objectsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedObjects,
    }): CancelablePromise<Objects> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/objects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Объекты, создаются в разделе "настройки > объекты"
     * @returns void
     * @throws ApiError
     */
    public static objectsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/objects/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns PaginatedObjectsGroupsList
     * @throws ApiError
     */
    public static objectsGroupsList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedObjectsGroupsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/groups',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns ObjectsGroups
     * @throws ApiError
     */
    public static objectsGroupsCreate({
        requestBody,
    }: {
        requestBody?: ObjectsGroups,
    }): CancelablePromise<ObjectsGroups> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/objects/groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns ObjectsGroups
     * @throws ApiError
     */
    public static objectsGroupsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<ObjectsGroups> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns ObjectsGroups
     * @throws ApiError
     */
    public static objectsGroupsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: ObjectsGroups,
    }): CancelablePromise<ObjectsGroups> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/objects/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns ObjectsGroups
     * @throws ApiError
     */
    public static objectsGroupsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedObjectsGroups,
    }): CancelablePromise<ObjectsGroups> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/objects/groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * API Группы объектов, создаются в разделе "настройки > группы объектов"
     * @returns void
     * @throws ApiError
     */
    public static objectsGroupsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/objects/groups/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns PaginatedGuardedObjectsList
     * @throws ApiError
     */
    public static objectsGuardedList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedGuardedObjectsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/guarded',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns GuardedObjects
     * @throws ApiError
     */
    public static objectsGuardedCreate({
        requestBody,
    }: {
        requestBody?: GuardedObjects,
    }): CancelablePromise<GuardedObjects> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/objects/guarded',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns GuardedObjects
     * @throws ApiError
     */
    public static objectsGuardedRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<GuardedObjects> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/guarded/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns GuardedObjects
     * @throws ApiError
     */
    public static objectsGuardedUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: GuardedObjects,
    }): CancelablePromise<GuardedObjects> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/objects/guarded/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns GuardedObjects
     * @throws ApiError
     */
    public static objectsGuardedPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedGuardedObjects,
    }): CancelablePromise<GuardedObjects> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/objects/guarded/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * Защищаемые объекты, создаются в разделе "мониторинг > + новый объект"
     * @returns void
     * @throws ApiError
     */
    public static objectsGuardedDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/objects/guarded/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Получить расписание на объект по месяцу в формате excel
     * @returns Objects
     * @throws ApiError
     */
    public static objectsScheduleXlsRetrieve({
        id,
        monthNumber,
        year,
    }: {
        id: number,
        monthNumber: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
        year: number,
    }): CancelablePromise<Objects> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/schedule-xls/{id}/',
            path: {
                'id': id,
            },
            query: {
                'month_number': monthNumber,
                'year': year,
            },
        });
    }

    /**
     * @returns PaginatedShortObjectsList
     * @throws ApiError
     */
    public static objectsShortList({
        isGuarded,
        isOnline,
        limit,
        offset,
        ordering,
        search,
    }: {
        isGuarded?: boolean,
        isOnline?: boolean,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedShortObjectsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/objects/short',
            query: {
                'is_guarded': isGuarded,
                'is_online': isOnline,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

}
