import { useEffect, useState } from "react";
import { message, Upload, UploadFile, UploadProps } from "antd";
import { RcFile, UploadChangeParam } from "antd/es/upload/interface";

import { L } from "modules/L18n";
import { getFileToBase64 } from "utils/getFileToBase64";

const dummyRequest = ({ onSuccess }: { file: any; onSuccess?: any }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const beforeUpload = (file: any) => {
  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Размер файла должен быть меньше 2 мегабайт!");
    return false;
  }

  return isLt2M;
};

export interface IAvatarProps {
  photoUrl?: string | null;
  onUpload: (file: Blob) => void;
}

const Avatar = ({ photoUrl, onUpload }: IAvatarProps) => {
  const [photo, setPhoto] = useState(photoUrl);

  const handleChange: UploadProps["onChange"] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === "done") {
      getFileToBase64(info.file.originFileObj as RcFile, (url) => {
        setPhoto(url);
        onUpload(info.file.originFileObj as Blob);
      });
    }
  };

  useEffect(() => {
    setPhoto(photoUrl);
  }, [photoUrl]);

  return (
    <Upload
      accept="image/png, image/jpeg"
      maxCount={1}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      // action={FILE_UPLOAD_URL}
      beforeUpload={beforeUpload}
      customRequest={dummyRequest}
      onChange={handleChange}
    >
      {photo ? (
        <img src={photo} alt="avatar" style={{ width: "100%" }} />
      ) : (
        <div className="ant-upload-text">{L("OBJECT_MODAL_AVATAR_UPLOAD")}</div>
      )}
    </Upload>
  );
};

export default Avatar;
