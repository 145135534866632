import { action, makeObservable, observable } from "mobx";

import { ActsService } from "api/services/ActsService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { Act } from "./ActsReports.interfaces";
import { downloadFile } from "utils/AsyncDownloadFile";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { stores } from "store";
import { EventTypeEnum } from "modules/Events";

interface IFIlters extends IFiltersProps, IPaginatedProps {
  dateFrom?: string;
  dateTo?: string;
  object?: number;
}

export class ActReportsStore extends BaseCrudStore<Act, Act, Act, IFIlters> {
  @observable isActReportDownloading: boolean = false;

  constructor() {
    super(
      new BaseApiService({
        getAll: ActsService.actsList,
      })
    );

    makeObservable(this);
  }

  @action
  readAct = (act: Act) => {
    if (act.is_read) {
      return;
    }

    stores.objectStore.changeNewsCount(act.object_id, EventTypeEnum.ACT, -1);

    const entity = this.entities.find((item) => item.id === act.id);

    if (entity) {
      entity.is_read = true;
    }

    this.entities = [...this.entities];
  };

  @action
  async downloadActReport(act: Act) {
    this.isActReportDownloading = true;
    const file = await ActsService.actsDocxRetrieve({ id: act.id });
    downloadFile(file, file.name || "ActReport.docx");
    this.readAct(act);
    this.isActReportDownloading = false;
  }
}

export type ActReportsStoreType = ActReportsStore;
