import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

const Editor = ({ onChange, onSubmit, submitting, value, buttonText }: any) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        {buttonText}
      </Button>
    </Form.Item>
  </div>
);

export default Editor;
