import { action, makeObservable, observable, override } from "mobx";

import { EmployeesService, Task, TasksService, TaskUpdate } from "api";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { _RoleTypeEnum } from "./../Employees/Employees.interfaces";

interface IFilters extends IPaginatedProps, IFiltersProps {
  objectId: number;
}

export class TaskStore extends BaseCrudStore<Task, Task, TaskUpdate, IFilters> {
  @observable employees!: any[];
  @observable isEmployeesLoading: boolean = false;

  constructor() {
    super(
      new BaseApiService({
        create: TasksService.tasksCreate,
        get: TasksService.tasksRetrieve,
        getAll: TasksService.tasksList,
        delete: TasksService.tasksDestroy,
      })
    );

    makeObservable(this);
  }

  @override
  async create(createInput: any): Promise<void> {
    const employees = createInput.employees;
    delete createInput["employees"];
    const task_employees = Array.from(
      employees.map((empId: Number) => {
        return {
          is_completed: false,
          comment: null,
          employee: empId,
        };
      })
    );
    createInput.task_employees = task_employees;
    await TasksService.tasksCreate({ requestBody: createInput });
  }

  @action
  async loadEmployees(objectId: number) {
    this.isEmployeesLoading = true;
    let { results } = await EmployeesService.employeesList({
      objectId,
      limit: 9999999,
      roles: [
        _RoleTypeEnum.REGIONAL_REPRESENTATIVE,
        _RoleTypeEnum.SHIFT_SUPERVISOR,
      ],
    });
    this.employees = results || [];
    this.isEmployeesLoading = false;
  }

  @override
  async update(updateInput: TaskUpdate) {
    const entity = await TasksService.tasksUpdateUpdate({
      id: updateInput.id,
      requestBody: updateInput,
    });

    this.entities
      .filter((x: Task) => x.id === entity.id)
      .map((x: Task) => {
        return (x = entity as Task);
      });

    return entity;
  }
}

export type TaskStoreType = TaskStore;
