import {
  action,
  makeObservable,
  observable,
  override,
  runInAction,
} from "mobx";
import moment from "moment-timezone";

import { Duty, Employees, ObjectsService, SetDuties } from "api";
import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { ScheduleService } from "api/services/ScheduleService";
import { downloadFile } from "utils/AsyncDownloadFile";

import { GuardPost } from "./SecurityPosts.interfaces";

interface IFilters extends IFiltersProps, IPaginatedProps {
  object: IIdentifiable["id"];
}

export class SecurityPostStore extends BaseCrudStore<
  GuardPost,
  GuardPost,
  GuardPost,
  IFilters
> {
  @observable isUserSchedulesLoading: boolean = false;
  @observable userSchedules: Duty[] = [];

  constructor() {
    super(
      new BaseApiService({
        create: ScheduleService.schedulePostCreate,
        getAll: ScheduleService.schedulePostList,
        get: ScheduleService.schedulePostRetrieve,
        delete: ScheduleService.schedulePostDestroy,
        update: ScheduleService.schedulePostPartialUpdate,
      })
    );

    makeObservable(this);
  }

  @override
  async getAll(props: any) {
    const getAll = await super.getAll(props);
    this.getSchedule(props.dateFrom, props.dateTo);
    return getAll;
  }

  @action
  async updateEmployeeDuty(
    id: IIdentifiable["id"],
    date: string,
    hours: number,
    securityPostId: number,
    employeeId: number
  ) {
    let result: Duty;

    if (id) {
      result = await ScheduleService.scheduleDutyUpdate({
        id,
        requestBody: {
          id,
          date,
          delta: hours,
          post_employee: employeeId,
          post: securityPostId,
        },
      });

      this.userSchedules = this.userSchedules.map((x) => {
        if (x.id === result.id) {
          return result;
        }
        return x;
      });
    } else {
      result = await ScheduleService.scheduleDutyCreate({
        // @ts-ignore
        requestBody: {
          date,
          delta: hours,
          post_employee: employeeId,
          post: securityPostId,
        },
      });

      this.userSchedules = [...this.userSchedules, result];
    }

    return result;
  }

  @action
  async updateEmployeeSchedule(
    date: moment.Moment,
    hours: number,
    postEmployeeId: number
  ) {
    const date_pattern = date.format("YYYY-MM-");
    const daysInMonth = date.daysInMonth();

    const duties = [];

    for (let i = 1; i <= daysInMonth; i++) {
      const day = i < 10 ? `0${i}` : i;
      const date = `${date_pattern}${day}`;

      duties.push({
        date,
        delta: hours,
      });
    }

    await ScheduleService.scheduleEmployeeSetDutiesCreate({
      id: postEmployeeId,
      requestBody: {
        duties: duties as SetDuties["duties"],
      },
    });

    const startOfMonth = date.utcOffset(0, true).startOf("month").toISOString();
    const endOfMonth = date.utcOffset(0, true).endOf("month").toISOString();

    await this.getSchedule(startOfMonth, endOfMonth);
  }

  @action
  async setEmployees(
    year: number,
    month: number,
    securityPost: GuardPost,
    employees: Employees[]
  ) {
    const employeeIds = employees.map((x) => x.id);

    const result = await ScheduleService.schedulePostSetEmployeesCreate({
      id: securityPost.id,
      requestBody: { employees: employeeIds },
    });

    this.entities = this.entities.map((x) => {
      if (x.id === securityPost.id) {
        // @ts-ignore
        x.post_employees = result.post_employees;
      }
      return x;
    });
  }

  @action
  async getSchedule(dateFrom: string, dateTo: string) {
    const postEmployeeIds = this.entities.flatMap((x) =>
      x.post_employees.map((y) => y.id)
    );

    if (postEmployeeIds.length === 0) {
      runInAction(() => {
        this.userSchedules = [];
      });

      return;
    }

    runInAction(() => {
      this.isUserSchedulesLoading = true;
    });

    const { results } = await ScheduleService.scheduleDutyList({
      postEmployeeIds,
      dateFrom,
      dateTo,
      limit: 99999999,
    });

    runInAction(() => {
      this.isUserSchedulesLoading = false;
      this.userSchedules = results || [];
    });
  }

  @action
  async downloadTimeReport(objectId: number, year: number, month: number) {
    const file = (await ObjectsService.objectsScheduleXlsRetrieve({
      year,
      id: objectId,
      monthNumber: month as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
    })) as unknown as Blob & { name?: string };

    downloadFile(file, file.name || "Отчет по рабочему времени.xlsx");
  }
}

export type SecurityPostStoreType = SecurityPostStore;
