import { Marker, MarkerProps } from "react-leaflet";

const MarkerWithOpenedPopup = (props: MarkerProps) => {
  return (
    <Marker
      ref={(ref) => {
        if (ref) {
          setTimeout(() => ref?.openPopup(), 0);
        }
      }}
      {...props}
    />
  );
};

export default MarkerWithOpenedPopup;
