import { Col, Menu, Row, Divider, MenuProps } from "antd";

import "./Header.styles.less";
import Time from "./elements/Time";
import logo from "./images/logo-main.svg";
import { Logout } from "modules/Auth";

type MenuItem = Required<MenuProps>["items"][number];
type OnSelect = Required<MenuProps>["onSelect"];

export interface IHeaderProps {
  items: MenuItem[];
  pathname: string;
  onMenuSelect: OnSelect;
}

const Header = ({ items, pathname, onMenuSelect }: IHeaderProps) => (
  <Row className={"header-container"} align="middle" justify="end">
    <Col>
      <img src={logo} alt="Logo" />
    </Col>

    <Col flex={1}>
      <Menu
        mode="horizontal"
        selectedKeys={[pathname]}
        style={{
          lineHeight: "64px",
          justifyContent: "flex-end",
        }}
        items={items}
        onSelect={onMenuSelect}
      />
    </Col>

    <Divider type="vertical" />

    <Col style={{ margin: "0 10px" }} className="dates">
      <Time />
    </Col>

    <Divider type="vertical" />

    <Col style={{ width: 64 }} className="logoutBtn">
      <Logout style={{ height: 64, lineHeight: "64px" }} />
    </Col>
  </Row>
);

export default Header;
