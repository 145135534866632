import { List, Comment } from "antd";
import { CommentProps } from "antd/lib/comment";
import moment from "moment";

import { EventComment } from "modules/Events";

interface ICommentListProps {
  comments: EventComment[];
}

const CommentList = ({ comments }: ICommentListProps) => (
  <List
    dataSource={comments.map((c: EventComment) => ({
      author: c.employee_name,
      content: <p>{c.text}</p>,
      datetime: moment(c.creation_time).fromNow(),
    }))}
    itemLayout="horizontal"
    renderItem={(props: CommentProps) => <Comment {...props} />}
  />
);

export default CommentList;
