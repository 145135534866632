import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ruRU from "antd/es/locale/ru_RU";

import "./App.css";
import { Router } from "./components/Router";
import { authenticationStore } from "modules/Auth";
import { StoresProvider } from "store/store.context";
import ViewEvent from "modules/Events/components/ViewEvent";

import moment from "moment";
import momentTZ from "moment-timezone";

import ru from "modules/L18n/momentRU";

moment.locale("ru");
momentTZ.defineLocale("ru", ru);
momentTZ.locale("ru");

authenticationStore.init();

function App() {
  return (
    <ConfigProvider locale={ruRU}>
      <HelmetProvider>
        <StoresProvider>
          <BrowserRouter>
            <Router />
            <ViewEvent />
          </BrowserRouter>
        </StoresProvider>
      </HelmetProvider>
    </ConfigProvider>
  );
}

export default App;
