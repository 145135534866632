import { Row } from "antd";

import { getLink } from "utils";
import { EventFile } from "modules/Events";

interface IEventFileProps {
  file: EventFile;
}

const EventFileComponent = ({ file }: IEventFileProps) => {
  if (file.video) {
    const href = getLink(file.video) || "";

    return (
      <Row key={file.id} justify="center" align="middle">
        <video height={400} controls>
          <source src={href} type={file.content_type || "video/mp4"}></source>
        </video>
        {/* {!file.converted && (
          <Spin spinning>
            <Row style={{ height: 400, color: "black", fontWeight: "bold" }}>
              Видео обрабатывается, пожалуйста подождите или попробуйте открыть
              позже...
            </Row>
          </Spin>
        )} */}
      </Row>
    );
  }

  if (file.photo) {
    const href = getLink(file.photo) || "";

    return (
      <div key={file.id} className={"gallery"}>
        <a href={href} target="blank">
          <img src={href} alt="" />
        </a>
      </div>
    );
  }

  return null;
};

export default EventFileComponent;
