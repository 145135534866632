import { action, makeObservable } from "mobx";

import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { EmployeesService } from "api/services/EmployeesService";
import { Employees, EditEmployees, NewEmployees, _RoleTypeEnum } from "./Employees.interfaces";

interface IFilters extends IFiltersProps, IPaginatedProps {
  objectId?: IIdentifiable["id"];
  roles?: _RoleTypeEnum[];
}

export class EmployeeStore extends BaseCrudStore<
  Employees,
  NewEmployees,
  EditEmployees,
  IFilters
> {
  // @observable messages!: MessageDto[];
  // @observable chatEmployeeId!: number;

  constructor() {
    super(
      new BaseApiService({
        create: EmployeesService.employeesCreateCreate,
        getAll: EmployeesService.employeesList,
        update: EmployeesService.employeesUpdate,
        delete: EmployeesService.employeesDestroy,
      })
    );

    makeObservable(this);
  }

  // @action
  // async loadMessages(employeeId: number) {
  //   let result = await service.loadMessages(employeeId);
  //   this.messages = result;
  // }

  // @action
  // async sendMessage(employeeId: number, text: string) {
  //   let result = await service.sendMessage(employeeId, text);
  //   this.messages = [result, ...this.messages];
  // }

  // @action
  // setChatEmployeeId(employeeId: number) {
  //   this.chatEmployeeId = employeeId;
  // }

  // @action
  // async newMessage(employeeId: number) {
  //   if (this.chatEmployeeId === employeeId) {
  //     await this.loadMessages(employeeId);
  //   }
  // }

  // @action
  // increaseMessagesCount(employeeId: number) {
  //   if (!this.entities) {
  //     return;
  //   }

  //   if (this.chatEmployeeId === employeeId) {
  //     return;
  //   }

  //   this.entities = {
  //     ...this.entities,
  //     items: this.entities.items.map((e: EmployeeDto) => {
  //       if (e.id === employeeId) {
  //         e.messagesCount = e.messagesCount || 0;
  //         e.messagesCount++;
  //       }
  //       return e;
  //     }),
  //   };
  // }

  // @action
  // resetMessagesCount(employeeId: number) {
  //   if (!this.entities) {
  //     return;
  //   }

  //   this.entities = {
  //     ...this.entities,
  //     items: this.entities.items.map((e: EmployeeDto) => {
  //       if (e.id === employeeId) {
  //         e.messagesCount = 0;
  //       }
  //       return e;
  //     }),
  //   };
  // }

  @action
  updateEmployee(employee: Employees) {
    if (!this.entities) {
      return;
    }

    if (!this.entities.some((e) => e.id === employee.id)) {
      return;
    }

    this.entities = {
      ...this.entities,
      ...this.entities.map((e) => {
        if (e.id === employee.id) {
          return employee;
        }
        return e;
      }),
    };
  }
}

export type EmployeeStoreType = EmployeeStore;
