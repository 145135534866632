import { action, computed, makeObservable, observable, override } from "mobx";

import { EventsService } from "api";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { IFiltersProps, IIdentifiable, IPaginatedProps } from "core/types";
import { authenticationStore } from "modules/Auth";
import { stores } from "store";
import {
  Event,
  EventTypeEnum,
  DetailEvent,
  EventComment,
  EventFile,
  PatchedDetailEvent,
} from "./Events.interfaces";

interface IFilters extends IFiltersProps, IPaginatedProps {
  object?: IIdentifiable["id"];
  employee?: IIdentifiable["id"];
  creationDateGte?: string;
  creationDateLte?: string;
  types?: typeof EventTypeEnum[];
}

class EventStore extends BaseCrudStore<
  Event,
  Event,
  PatchedDetailEvent,
  IFilters
> {
  @observable isEventsFilterOpen: boolean = false;

  @observable isViewEventModalVisible: boolean = false;
  @observable sosComment: string = "";
  @observable files: EventFile[] = [];
  @observable isFilesLoading: boolean = false;
  @observable comments: EventComment[] = [];
  @observable isCommentsLoading: boolean = false;
  @observable isCommentLoading: boolean = false;
  @observable selectedEvent: Event | null = null;

  @observable showAllObjects: boolean = false;

  constructor() {
    super(
      new BaseApiService<Event>({
        // create: EventsService.eventsCreate,
        get: EventsService.eventsRetrieve,
        getAll: EventsService.eventsList,
        update: EventsService.eventsPartialUpdate,
        delete: EventsService.eventsDestroy,
      })
    );

    makeObservable(this);
  }

  @computed
  get isFilterApplied() {
    return (
      this.showAllObjects ||
      this.filters.employee ||
      this.filters.creationDateGte ||
      this.filters.creationDateLte ||
      this.filters.search
    );
  }

  @override
  async get(eventId: number) {
    const promises = [this.loadComments(eventId), this.loadFiles(eventId)];

    const event = await super.get(eventId);

    if (
      this.entity?.event_type === EventTypeEnum.ALARM &&
      (this.entity as DetailEvent)?.comments.length
    ) {
      this.sosComment = (this.entity as DetailEvent).comments[0].text || "";
    }

    await Promise.all(promises);

    if (event.event_type !== EventTypeEnum.ALARM && !event.is_processed) {
      stores.objectStore.changeNewsCount(
        event.obj,
        event.event_type as any,
        -1
      );
    }

    return event;
  }

  @action
  loadComments = async (eventId: number) => {
    this.isCommentsLoading = true;
    const { results } = await EventsService.eventsCommentsList({
      event: eventId,
      limit: 999999999,
    });
    this.comments = results || [];
    this.isCommentsLoading = false;
  };

  @action
  loadFiles = async (eventId: number) => {
    this.isFilesLoading = true;
    const { results } = await EventsService.eventsFilesList({
      event: eventId,
      limit: 999999999,
    });
    this.files = results || [];
    this.isFilesLoading = false;
  };

  @action
  show = async (eventId: number) => {
    const event = await this.get(eventId);
    if (event.event_type !== EventTypeEnum.ALARM) {
      this.setViewed(eventId);
    }
    this.isViewEventModalVisible = true;
  };

  @action
  close = async () => {
    this.isViewEventModalVisible = false;
    this.comments = [];
    this.files = [];
  };

  @action
  showEventFilter = () => {
    this.isEventsFilterOpen = true;
  };

  @action
  closeFilter = () => {
    this.isEventsFilterOpen = false;
  };

  @action
  addComment = async (text: string) => {
    const employeeId = authenticationStore.user?.employee?.id;
    const eventId = this.entity?.id;

    if (!employeeId || !eventId) {
      return;
    }

    this.isCommentLoading = true;

    await EventsService.eventsCommentsCreate({
      requestBody: {
        text: text,
        event: eventId,
        employee: employeeId,
      },
    });

    await this.get(eventId);

    this.isCommentLoading = false;
  };

  @action
  startWorkDate = async (message: PatchedDetailEvent["message"]) => {
    if (!this.entity) {
      return;
    }

    await this.update({
      ...this.entity,
      message,
      work_in_progress: true,
      is_processed: true,
    });
    await this.get(this.entity.id);

    this.setViewed(this.entity.id);
  };

  @action
  endWorkDate = async (message: PatchedDetailEvent["message"]) => {
    if (!this.entity) {
      return;
    }

    await this.update({
      ...this.entity,
      message,
      work_in_progress: false,
    });

    await this.get(this.entity.id);
  };

  @action
  setViewed = (eventId: number) => {
    if (!this.entities) {
      return;
    }

    this.entities = this.entities.map((event) => {
      if (event.id === eventId) {
        event.is_processed = true;
      }
      return event;
    });
  };
}

export const eventStore = new EventStore();

export type EventStoreType = EventStore;
