import { makeAutoObservable, runInAction } from "mobx";

import { ApiError, UserService } from "api";
import {
  clearRefreshToken,
  getRefreshTokenFromStorage,
  getRememberMeFromStorage,
  setRefreshToken,
} from "./Auth.utils";
import {
  UserEmployeeProfile,
  AccessToken,
  ILoginModel,
  RefreshToken,
} from "./Auth.interfaces";

export class AuthenticationStore {
  user: UserEmployeeProfile | null = null;
  access: AccessToken | null = null;
  refresh: RefreshToken | null = null;
  rememberMe: boolean | null = false;
  isInitialized: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  async init() {
    this.refresh = getRefreshTokenFromStorage();
    this.rememberMe = getRememberMeFromStorage();

    if (this.refresh) {
      await this.refreshTokenPair();
    }

    if (this.access) {
      await this.loadUser();
    }

    runInAction(() => {
      this.isInitialized = true;
    });
  }

  get isAuthenticated(): boolean {
    return Boolean(this.access);
  }

  async refreshTokenPair() {
    let { refresh, rememberMe } = this;

    if (!refresh) {
      return;
    }

    try {
      const obtainedTokenPair = await UserService.userRefreshTokenCreate({
        requestBody: { refresh, access: "" },
      });

      this.setTokenPair(
        obtainedTokenPair.access,
        obtainedTokenPair.refresh,
        rememberMe
      );
    } catch (e: any) {
      if (e instanceof ApiError && e.status !== 401) {
        console.error(e);
      }

      this.clear();
    }
  }

  async login(model: ILoginModel) {
    let access: AccessToken;
    let refresh: RefreshToken;

    const obtainTokenPair = await UserService.userLoginCreate({
      requestBody: {
        username: model.username,
        password: model.password,
        // Для разграничения веб и мобильной авторизации
        mobile_app: false,
      },
    });

    access = obtainTokenPair.access;
    refresh = obtainTokenPair.refresh;

    this.setTokenPair(access, refresh, model.rememberMe);

    runInAction(() => {
      this.access = access;
      this.refresh = refresh;
    });

    await this.loadUser();
  }

  async logout() {
    try {
      await UserService.userLogoutRetrieve();
    } catch (e) {
      console.error(e);
    }

    this.clear();
  }

  private async loadUser() {
    const user = await UserService.userProfileRetrieve();

    runInAction(() => {
      this.user = user;
    });
  }

  private setTokenPair(
    access: AccessToken,
    refresh: RefreshToken,
    rememberMe: boolean | null
  ) {
    this.access = access;
    this.refresh = refresh;
    this.rememberMe = rememberMe;

    setRefreshToken(this.refresh, this.rememberMe ?? false);
  }

  private clear() {
    this.access = null;
    this.refresh = null;
    this.user = null;

    clearRefreshToken();
  }
}

export type AuthenticationStoreType = AuthenticationStore;

export const authenticationStore = new AuthenticationStore();
