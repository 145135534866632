import { Suspense } from "react";
import { Alert, Col, Layout } from "antd";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useStores } from "store";
import Spin from "components/Spin";
import "./UserLayout.styles.less";
import { usePageTitle } from "../Router";

const UserLayout = () => {
  const { authenticationStore } = useStores();
  const pageTitle = usePageTitle();

  const { isAuthenticated } = authenticationStore;

  if (isAuthenticated) {
    return <Navigate to="/objects/monitoring" replace />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Layout className="user-layout" style={{ minHeight: "100vh" }}>
        <Layout.Header className={"header"} />
        <Col className="container">
          <Suspense fallback={<Spin />}>
            <Alert.ErrorBoundary>
              <Outlet />
            </Alert.ErrorBoundary>
          </Suspense>
        </Col>
      </Layout>
    </>
  );
};

export default observer(UserLayout);
