import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "antd";
import moment from "moment-timezone";

import { useStores } from "store";
import { L } from "modules/L18n";
import { DetailEvent } from "modules/Events";
import { EventTypeEnum } from "entities";
import SosEvent from "./elements/SosEvent";
import GenericEvent from "./elements/GenericEvent";

const ViewEvent = () => {
  const { eventStore, objectStore } = useStores();

  const [comment, setComment] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [workStartDateSubmitting, setWorkStartDateSubmitting] = useState(false);
  const [workEndDateSubmitting, setWorkEndDateSubmitting] = useState(false);

  const { isViewEventModalVisible, entity } = eventStore;

  const event = entity as DetailEvent;

  const isSos = event?.event_type === EventTypeEnum.ALARM;

  const handleChange = (e: any) => {
    setComment(e.target.value);
  };

  const handleSosChange = (e: any) => {
    eventStore.sosComment = e.target.value;
  };

  const handleWorkStartDate = async () => {
    setWorkStartDateSubmitting(true);
    await eventStore.startWorkDate(eventStore.sosComment);
    // TODO: fix types
    objectStore.changeNewsCount(
      event!.obj,
      // @ts-ignore
      event?.event_type,
      -1
    );
    setWorkStartDateSubmitting(false);
  };

  const handleWorkEndDate = async () => {
    setWorkEndDateSubmitting(true);
    await eventStore.endWorkDate(eventStore.sosComment);
    setWorkEndDateSubmitting(false);
  };

  const handleSubmit = async () => {
    if (!comment) {
      return;
    }

    setSubmitting(true);

    await eventStore.addComment(comment);

    setSubmitting(false);
    setComment("");
  };

  // useEffect(() => {
  //   if (isSos || !event || event.is_processed) return;

  //   objectStore.changeNewsCount(
  //     event.obj,
  //     // @ts-ignore
  //     event.event_type,
  //     -1
  //   );

  //   eventStore.setViewed(event.id);
  // }, [event, eventStore, isSos, objectStore]);

  useEffect(() => {
    return () => {
      eventStore.sosComment = "";
    };
  }, [eventStore, event])

  if (!event) {
    return null;
  }

  return (
    <Modal
      className={"eventModal " + (isSos ? "eventModalSos" : "")}
      open={isViewEventModalVisible}
      onCancel={() => {
        eventStore.entity = null;
        eventStore.isViewEventModalVisible = false;
      }}
      footer={null}
      width={700}
    >
      <h1 style={{ fontSize: 26, textAlign: "center" }}>{event.object_name}</h1>
      <p style={{ fontSize: 18, textAlign: "center" }}>
        {`(${L("EVENTS_MODAL_DATETIME")}: ${moment(event!.creation_date).format(
          "DD.MM.YYYY HH:mm"
        )} 
            ${L("EVENTS_MODAL_AUTHOR")}: ${event.employee_name})`}
      </p>

      {isSos ? (
        <SosEvent
          event={eventStore.entity as DetailEvent}
          handleSosChange={handleSosChange}
          handleWorkEndDate={handleWorkEndDate}
          handleWorkStartDate={handleWorkStartDate}
          sosComment={eventStore.sosComment}
          workEndDateSubmitting={workEndDateSubmitting}
          workStartDateSubmitting={workStartDateSubmitting}
        />
      ) : (
        <GenericEvent
          comment={comment}
          event={eventStore.entity as DetailEvent}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      )}
    </Modal>
  );
};

export default observer(ViewEvent);
