import { action, makeObservable, observable } from "mobx";

import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { ObjectsService } from "api/services/ObjectsService";
import { GuardedObjects } from "api/models/GuardedObjects";
import { BaseApiService } from "core/services/BaseApiService";
import { IFiltersProps, IPaginatedProps } from "core/types";

interface IFilters extends IFiltersProps, IPaginatedProps {}

export class GuardedObjectStore extends BaseCrudStore<
  GuardedObjects,
  GuardedObjects,
  GuardedObjects,
  IFilters
> {
  constructor() {
    super(
      new BaseApiService({
        delete: ObjectsService.objectsGuardedDestroy,
        get: ObjectsService.objectsGuardedRetrieve,
        getAll: ObjectsService.objectsGuardedList,
        create: ObjectsService.objectsGuardedCreate,
        update: ObjectsService.objectsGuardedUpdate,
      })
    );

    makeObservable(this);
  }

  @observable employees: any[] = [];
  @observable isEmployeesLoading: boolean = false;

  @observable objectGuardedObjects: GuardedObjects[] = [];
  @observable isObjectGuardedObjectsLoading: boolean = false;

  @observable contracts: any[] = [];
  @observable isContractsLoading: boolean = false;

  @action
  async loadEmployees() {
    this.isEmployeesLoading = true;
    let result = await new Promise<[]>((resolve) =>
      setTimeout(() => resolve([]), 1000)
    );
    this.employees = result;
    this.isEmployeesLoading = false;
  }

  @action
  async loadContracts(guardedObjectId: number) {
    this.isContractsLoading = true;
    let result = await new Promise<[]>((resolve) =>
      setTimeout(() => resolve([]), 1000)
    );
    this.contracts = result;
    this.isContractsLoading = false;
  }

  @action
  async loadGuardedObjectsForObject(objectId: number) {
    this.isObjectGuardedObjectsLoading = true;
    let result = await new Promise<[]>((resolve) =>
      setTimeout(() => resolve([]), 1000)
    );
    this.objectGuardedObjects = result;
    this.isObjectGuardedObjectsLoading = false;
  }
}

export type GuardedObjectStoreType = GuardedObjectStore;
