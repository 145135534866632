import { CoordsService } from "api/services/CoordsService";
import { BaseApiService } from "core/services/BaseApiService";

export const coordsService = new BaseApiService({
  create: CoordsService.coordsCreate,
  get: CoordsService.coordsRetrieve,
  getAll: CoordsService.coordsList,
  update: CoordsService.coordsUpdate,
  delete: CoordsService.coordsDestroy,
});
