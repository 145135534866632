import { ReactEventHandler } from "react";
import { Col, Row, Tag, Tooltip } from "antd";
import { DeleteFilled, EditFilled, SnippetsTwoTone } from "@ant-design/icons";

import { getLink } from "utils";
import { L } from "modules/L18n";
import { Objects } from "entities/Objects";
import AlarmIcon from "../../images/alarm.png";
import NotificationIcon from "../../images/ntfc.png";
import MsgIcon from "../../images/msg.png";
import ImgIcon from "../../images/img.png";

interface ObjectRowProps {
  object: Objects;
  isSelected: boolean;
  collapsed: boolean;
  onClick: () => void;
  onEdit: () => void;
  onDelete: () => void;
}

const hasEvents = (object: Objects) =>
  !!object.news && !!Object.values(object.news).find((n) => n > 0);

const getCommonEventsCount = (object: Objects) =>
  object.news.acts + object.news.reports;

const eventsCountTooltipString = (o: Objects) => {
  let res = o.news.acts > 0 ? L("ACT_REPORT_TOOLTIP") + ": " + o.news.acts : "";

  if (o.news.reports > 0) {
    if (res) res += "\n";
    res = res + L("DAILY_REPORT_TOOLTIP") + ": " + o.news.reports;
  }

  return res;
};

const ObjectRow = ({
  object,
  isSelected,
  collapsed,
  onClick,
  onEdit,
  onDelete,
}: ObjectRowProps) => {
  const handleEdit: ReactEventHandler = (e) => {
    e.stopPropagation();
    onEdit();
  };

  const handleDelete: ReactEventHandler = (e) => {
    e.stopPropagation();
    onDelete();
  };

  return (
    <Row
      align="middle"
      onClick={onClick}
      className={`ant-menu-item ${isSelected ? "ant-menu-item-selected" : ""}`}
      style={{
        height: hasEvents(object) ? "165px" : "120px",
        paddingRight: 0,
        borderBottomStyle: "dashed",
        borderBottomWidth: 1,
        borderBottomColor: "gray",
        marginTop: 0,
        marginBottom: 0,
        paddingLeft: 0,
        display: "flex",
        flexFlow: "row wrap",
        maxWidth: "90%",
        margin: "10px auto",
        border: "none",
        boxShadow: "0 2px 2px rgba(0,0,0,0.1)",
        background: "#FFF",
        flexDirection: "column",
      }}
    >
      <Col
        style={{
          width: 5,
          height: "100%",
          backgroundColor: object.color || "",
        }}
      ></Col>

      <Col
        style={{
          width: collapsed ? "100%" : 100,
          height: "100%",
          marginLeft: 0,
        }}
      >
        <Row
          align={collapsed ? "top" : "middle"}
          style={{
            width: collapsed ? "100%" : 100,
            height: collapsed ? 95 : "100%",
            marginTop: collapsed ? 0 : 0,
            overflow: "hidden",
            marginBottom: collapsed ? 5 : 0,
          }}
        >
          <div
            style={{
              width: "100%",
              backgroundImage: `url(${getLink(object.photo_url)})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              height: "100%",
            }}
          />
        </Row>

        {collapsed && hasEvents(object) && (
          <Row
            justify="center"
            align="middle"
            style={{
              height: 38,
              flexWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            {!!object.news.alarms && (
              <Tag
                color="#f50"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 2px 0px",
                  width: 37,
                }}
              >
                <img style={{ marginRight: 2 }} src={AlarmIcon} alt="fire" />
                {object.news.alarms}
              </Tag>
            )}

            {!!object.news.infos && (
              <Tag
                color="#FF8000"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 2px 0px",
                  width: 37,
                }}
              >
                <img
                  style={{ marginRight: 2 }}
                  src={NotificationIcon}
                  alt="notification"
                />
                {object.news.infos}
              </Tag>
            )}

            {!!object.news.messages && (
              <Tag
                color="#2db7f5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px 2px 0px",
                  width: 37,
                }}
              >
                <img style={{ marginRight: 2 }} src={MsgIcon} alt="Msg" />
                {object.news.messages}
              </Tag>
            )}

            {!!object.news.prompt_reports && (
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={
                  L("PROMPT_REPORT_TOOLTIP") + ": " + object.news.prompt_reports
                }
              >
                <Tag
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 2px 0px",
                    width: 37,
                  }}
                >
                  <SnippetsTwoTone
                    style={{ marginRight: 2 }}
                    alt="Prompt Events"
                  />
                  {object.news.prompt_reports}
                </Tag>
              </Tooltip>
            )}

            {!!getCommonEventsCount(object) && (
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={eventsCountTooltipString(object)}
              >
                <Tag
                  color="#2fd48f"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px 2px 0px",
                    width: 37,
                  }}
                >
                  <img style={{ marginRight: 2 }} src={ImgIcon} alt="Events" />
                  {getCommonEventsCount(object)}
                </Tag>
              </Tooltip>
            )}
          </Row>
        )}

        {collapsed && (
          <Row
            style={{
              fontSize: 12,
              lineHeight: "15px",
              textAlign: "center",
            }}
          >
            {(object.name?.length || 0) > 15 && (
              <Tooltip placement="top" title={object.name}>
                <Row
                  style={{
                    textOverflow: "ellipsis",
                    width: "100%",
                    paddingLeft: "5px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {object.name}
                </Row>
              </Tooltip>
            )}

            {(object.name?.length || 0) <= 15 && (
              <Row
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  paddingLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textAlign: "center",
                }}
              >
                {object.name}
              </Row>
            )}
          </Row>
        )}
      </Col>

      {!collapsed && (
        <Col style={{ width: 153, margin: 10 }}>
          <Row gutter={[5, 5]}>
            {!!object.news.alarms && (
              <Tag color="#f50">
                <img style={{ marginRight: 2 }} src={AlarmIcon} alt="fire" />
                {object.news.alarms}
              </Tag>
            )}

            {!!object.news.infos && (
              <Tag color="#FF8000">
                <img
                  style={{ marginRight: 2 }}
                  src={NotificationIcon}
                  alt="notification"
                />
                {object.news.infos}
              </Tag>
            )}

            {!!object.news.messages && (
              <Tag color="#2db7f5">
                <img style={{ marginRight: 2 }} src={MsgIcon} alt="Msg" />
                {object.news.messages}
              </Tag>
            )}

            {!!object.news.prompt_reports && (
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={
                  L("PROMPT_REPORT_TOOLTIP") + ": " + object.news.prompt_reports
                }
              >
                <Tag>
                  <SnippetsTwoTone
                    style={{ marginRight: 2 }}
                    alt="Prompt Events"
                  />
                  {object.news.prompt_reports}
                </Tag>
              </Tooltip>
            )}

            {!!getCommonEventsCount(object) && (
              <Tooltip
                overlayStyle={{ whiteSpace: "pre-line" }}
                title={eventsCountTooltipString(object)}
              >
                <Tag color="#2fd48f">
                  <img style={{ marginRight: 2 }} src={ImgIcon} alt="Events" />
                  {getCommonEventsCount(object)}
                </Tag>
              </Tooltip>
            )}
          </Row>

          {(object.name?.length || 0) > 20 && (
            <Tooltip placement="top" title={object.name}>
              <Row
                style={{
                  fontSize: 16,
                  textOverflow: "ellipsis",
                  width: 170,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {object.name}
              </Row>
            </Tooltip>
          )}

          {(object.name?.length || 0) <= 20 && (
            <Row
              style={{
                fontSize: 16,
                textOverflow: "ellipsis",
                width: 170,
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {object.name}
            </Row>
          )}

          <Row>
            <EditFilled type="edit" onClick={handleEdit} />
            <DeleteFilled type="delete" onClick={handleDelete} />
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ObjectRow;
