import { makeObservable } from "mobx";

import { BaseApiService } from "core/services/BaseApiService";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { HeadOfSecurity } from "./HeadOfSecurity.interfaces";
import { headOfSecurityServiceMock } from "./HeadOfSecurity.mock";
import { BaseCrudStore } from "../../core/stores/BaseCrudStore";

export class HeadOfSecurityStore extends BaseCrudStore<
  HeadOfSecurity,
  HeadOfSecurity,
  HeadOfSecurity,
  IFiltersProps & IPaginatedProps
> {
  constructor() {
    super(
      new BaseApiService<HeadOfSecurity>({
        get: headOfSecurityServiceMock.headOfSecurityRetrieve,
        create: headOfSecurityServiceMock.headOfSecurityCreate,
        getAll: headOfSecurityServiceMock.headOfSecurityList,
        update: headOfSecurityServiceMock.headOfSecurityUpdate,
        delete: headOfSecurityServiceMock.headOfSecurityDestroy,
      })
    );

    makeObservable(this);
  }
}

export type HeadOfSecurityStoreType = HeadOfSecurityStore;
