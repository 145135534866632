import { useCallback, useEffect } from "react";
import { Form, DatePicker, Select, Input, Modal, Button, Switch } from "antd";
import { observer } from "mobx-react-lite";
import moment from "moment";

import { L } from "modules/L18n";
import { useStores } from "store";
import { getEmployeeFullName } from "entities";
import { compareToStartDate, compareToEndDate } from "utils/validators";

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const EventsFilter = () => {
  const { eventStore, objectStore, employeeStore } = useStores();

  const { isEventsFilterOpen } = eventStore;

  const { isEntitiesLoading: isEmployeesLoading, entities: employees } =
    employeeStore;

  const objectId = objectStore.selectedObject?.id;

  const [form] = Form.useForm();

  const getAll = useCallback(async () => {
    if (objectId) {
      await employeeStore.getAll({
        objectId,
        limit: 2147483647,
      });
    }
  }, [employeeStore, objectId]);

  const applyFilter = async () => {
    const values = await form.validateFields();

    const { creationDateGte, creationDateLte, showAllObjects, ...rest } =
      values;

    eventStore.setFilters(
      {
        ...rest,
        object: showAllObjects ? null : objectStore.selectedObject?.id,
        creationDateGte:
          creationDateGte &&
          moment(creationDateGte)
            .utcOffset(0, true)
            ?.startOf("day")
            .toISOString(),
        creationDateLte:
          creationDateLte &&
          moment(creationDateLte)
            .utcOffset(0, true)
            ?.endOf("day")
            .toISOString(),
      },
      true
    );

    eventStore.showAllObjects = showAllObjects;

    eventStore.closeFilter();
  };

  const clearFilter = async () => {
    eventStore.showAllObjects = false;
    eventStore.setFilters(
      {
        object: objectStore.selectedObject?.id,
        creationDateGte: undefined,
        creationDateLte: undefined,
        employee: undefined,
        search: undefined,
      },
      true
    );
    eventStore.closeFilter();
  };

  useEffect(() => {
    getAll();
  }, [objectId, isEventsFilterOpen, getAll]);

  return (
    <Modal
      className=""
      open={isEventsFilterOpen}
      title={L("EVENTS_FILTER_TITLE")}
      footer={
        <>
          <Button onClick={clearFilter}>{L("EVENTS_FILTER_CLEAR")}</Button>
          <Button onClick={applyFilter} type="primary">
            {L("EVENTS_FILTER_APPLY")}
          </Button>
        </>
      }
      onCancel={() => {
        eventStore.closeFilter();
      }}
      width={550}
      cancelText={L("EVENTS_FILTER_CLEAR")}
      okText={L("EVENTS_FILTER_APPLY")}
      onOk={() => {
        applyFilter();
      }}
    >
      <Form form={form}>
        <Form.Item
          name="showAllObjects"
          label={L("EVENTS_FILTER_SHOW_ALL_OBJECTS")}
          initialValue={eventStore.showAllObjects}
          valuePropName={"checked"}
          {...formItemLayout}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name="creationDateGte"
          label={L("COMMON_FROM")}
          rules={[
            {
              validator: compareToEndDate(form, "creationDateLte"),
            },
          ]}
          initialValue={
            eventStore.filters.creationDateGte
              ? moment(eventStore.filters.creationDateGte)
              : null
          }
          {...formItemLayout}
        >
          <DatePicker
            placeholder={L("COMMON_FROM")}
            allowClear={true}
            mode={"date"}
            format="DD.MM.YYYY"
          />
        </Form.Item>

        <Form.Item
          name="creationDateLte"
          label={L("COMMON_TO")}
          rules={[
            {
              validator: compareToStartDate(form, "creationDateGte"),
            },
          ]}
          initialValue={
            eventStore.filters.creationDateLte
              ? moment(eventStore.filters.creationDateLte)
              : null
          }
          {...formItemLayout}
        >
          <DatePicker
            placeholder={L("COMMON_TO")}
            allowClear={true}
            format="DD.MM.YYYY"
          />
        </Form.Item>

        <Form.Item
          name="employee"
          label={L("Employee")}
          initialValue={eventStore.filters.employee}
          {...formItemLayout}
        >
          <Select
            disabled={!employees || !employees.length}
            loading={isEmployeesLoading}
            allowClear={true}
            placeholder={L("TRACKING_SELECTEMPLOYEE_LABEL")}
            style={{ width: "100%" }}
          >
            {(employees || []).map((e) => (
              <Select.Option key={e.id} value={e.id}>
                {getEmployeeFullName(e)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="search"
          label={L("Text")}
          initialValue={eventStore.filters.search}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(EventsFilter);
