import { ScheduleService } from "api/services/ScheduleService";
import { BaseApiService } from "core/services/BaseApiService";

export const guardPostsService = new BaseApiService({
  create: ScheduleService.schedulePostCreate,
  get: ScheduleService.schedulePostRetrieve,
  getAll: ScheduleService.schedulePostList,
  update: ScheduleService.schedulePostUpdate,
  delete: ScheduleService.schedulePostDestroy,
});
