import { makeObservable } from "mobx";

import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { ContractsService } from "api/services/ContractsService";
import { Contractors, CreateContractors } from "./Contractors.interfaces";

interface IFilters extends IFiltersProps, IPaginatedProps {}

export class ContractorsStore extends BaseCrudStore<
  Contractors,
  CreateContractors,
  Contractors,
  IFilters
> {
  constructor() {
    super(
      new BaseApiService({
        create: ContractsService.contractsContractorsCreate,
        get: ContractsService.contractsContractorsRetrieve,
        getAll: ContractsService.contractsContractorsList,
        update: ContractsService.contractsContractorsUpdate,
        delete: ContractsService.contractsContractorsDestroy,
      })
    );

    makeObservable(this);
  }
}

export type ContractorsStoreType = ContractorsStore;
