import { Row } from "antd";
import { observer } from "mobx-react-lite";

import { useStores } from "store";

import logoutIcon from "./logout.svg";

interface ILogoutProps {
  style?: React.CSSProperties;
}

const Logout = ({ style }: ILogoutProps) => {
  const { authenticationStore } = useStores();

  return (
    <Row style={style} onClick={authenticationStore?.logout}>
      <Row
        justify={"center"}
        align={"middle"}
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
      >
        <Row
          justify={"center"}
          align={"middle"}
          style={{ height: 40, width: 40 }}
        >
          <img src={logoutIcon} alt="logout" />
        </Row>
      </Row>
    </Row>
  );
};

export default observer(Logout);
