import React from "react";
import {
  CompassOutlined,
  EyeOutlined,
  BarChartOutlined,
  InfoCircleOutlined,
  ControlOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Navigate } from "react-router-dom";

import {UserProfile} from "api";

import { IAppRoute } from "./Router.interfaces";
import AppLayout from "../AppLayout";
import UserLayout from "../UserLayout";

const DailyReports = React.lazy(() => import("scenes/Reports/DailyReports"));
const Login = React.lazy(() => import("scenes/Login"));
const ObjectsManage = React.lazy(() => import("scenes/Objects/Manage"));
const ObjectsMonitoring = React.lazy(() => import("scenes/Objects/Monitoring"));
const ObjcectsTracking = React.lazy(() => import("scenes/Objects/Tracking"));
const GuardedObjects = React.lazy(
  () => import("scenes/GuardedObjects/GuardedObjects")
);
const Contractors = React.lazy(() => import("scenes/Contractors"));
const ReportsLogs = React.lazy(() => import("scenes/Reports/Logs"));
const ObjectGroup = React.lazy(() => import("scenes/ObjectGroup"));
const PromptReports = React.lazy(() => import("scenes/Reports/Prompt"));
const ActReports = React.lazy(() => import("scenes/Reports/Acts"));
const Contracts = React.lazy(() => import("scenes/GuardedObjects/Contracts"));
const Users = React.lazy(() => import("scenes/Users"));

export const userRouter: IAppRoute[] = [
  {
    key: "user",
    path: "user",
    isLayout: true,
    element: <UserLayout />,
    children: [
      {
        key: "login",
        path: "login",
        title: "LogIn",
        element: <Login />,
      },
    ],
  },
];

export const objectsRouter: IAppRoute[] = [
  {
    key: "objects",
    path: "objects",
    children: [
      {
        key: "objects.monitoring",
        path: "monitoring",
        title: "ROUTE_MONITORING",
        showInMenu: true,
        icon: <CompassOutlined />,
        element: <ObjectsMonitoring />,
      },
      {
        key: "objects.tracking",
        path: "tracking",
        title: "ROUTE_TRACKING",
        showInMenu: true,
        icon: <EyeOutlined />,
        element: <ObjcectsTracking />,
      },
      {
        key: "objects.manage",
        path: "manage",
        title: "ROUTE_MANAGE",
        showInMenu: true,
        icon: <ControlOutlined />,
        element: <ObjectsManage />,
      },
    ],
  },
];



export const reportsRouter: IAppRoute[] = [
  {
    key: "reports",
    path: "reports",
    title: "ROUTE_REPORTS",
    showInMenu: true,
    icon: <BarChartOutlined />,
    children: [
      {
        key: "reports.logs",
        path: "logs",
        title: "ROUTE_LOGS",
        icon: <BarChartOutlined />,
        showInMenu: true,
        element: <ReportsLogs />,
      },
      {
        key: "reports.dailyreport",
        path: "daily",
        title: "ROUTE_DAILY_REPORTS",
        icon: <BarChartOutlined />,
        showInMenu: true,
        element: <DailyReports />,
      },
      {
        key: "reports.actreports",
        path: "act",
        permission: (user: UserProfile) => {if(user) { return user.is_staff} else return true},
        title: "ROUTE_ACT_REPORTS",
        icon: <BarChartOutlined />,
        showInMenu: true,
        element: <ActReports />,
      },
      {
        key: "reports.promptreports",
        path: "prompt",
        title: "ROUTE_PROMPT_REPORTS",
        icon: <BarChartOutlined />,
        showInMenu: true,
        element: <PromptReports />,
      },
    ],
  },
];

export const settingsRouter: IAppRoute[] = [
  {
    key: "settings",
    path: "settings",
    permission: (user: UserProfile) => {if(user) { return user.is_staff} else return true},
    title: "ROUTE_SETTINGS",
    showInMenu: true,
    icon: <InfoCircleOutlined />,
    children: [
      {
        path: "objectgroups",
        title: "ROUTE_OBJECT_GROUP",
        key: "settings.objectgroups",
        icon: <InfoCircleOutlined />,
        showInMenu: true,
        element: <ObjectGroup />,
      },
      {
        path: "objects",
        title: "ROUTE_GUARDED_OBJECTS",
        key: "settings.guardedobjects",
        icon: <InfoCircleOutlined />,
        showInMenu: true,
        element: <GuardedObjects />,
      },
      {
        path: "contractors",
        title: "ROUTE_CONTRACTORS",
        key: "settings.contractors",
        icon: <InfoCircleOutlined />,
        showInMenu: true,
        element: <Contractors />,
      },
      {
        path: "contracts",
        title: "ROUTE_CONTRACTS",
        key: "settings.contracts",
        icon: <InfoCircleOutlined />,
        showInMenu: true,
        element: <Contracts />,
      },
    ],
  },
];

const appRouter: IAppRoute[] = [
  {
    key: "home",
    path: "/",
    element: <AppLayout />,
    isLayout: true,
    children: [
      ...objectsRouter,
      {
        key: "users",
        path: "users",
        permission: (user: UserProfile) => {if(user) { return user.is_staff} else return true},
        title: "ROUTE_HEADSOFSECURITY",
        showInMenu: true,
        icon: <TeamOutlined />,
        element: <Users />,
      },
      ...reportsRouter,
      ...settingsRouter,
    ],
  },
  {
    key: "404",
    path: "*",
    element: <Navigate to="/user/login" />,
  },
];

export const routers = [...userRouter, ...appRouter];
