/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocCount } from '../models/DocCount';
import type { Documents } from '../models/Documents';
import type { PaginatedDocumentsList } from '../models/PaginatedDocumentsList';
import type { PatchedDocuments } from '../models/PatchedDocuments';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns PaginatedDocumentsList
     * @throws ApiError
     */
    public static documentsList({
        limit,
        objectId,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        objectId?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedDocumentsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents',
            query: {
                'limit': limit,
                'object_id': objectId,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns Documents
     * @throws ApiError
     */
    public static documentsCreate({
        requestBody,
    }: {
        requestBody: Documents,
    }): CancelablePromise<Documents> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns Documents
     * @throws ApiError
     */
    public static documentsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Documents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns Documents
     * @throws ApiError
     */
    public static documentsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Documents,
    }): CancelablePromise<Documents> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns Documents
     * @throws ApiError
     */
    public static documentsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedDocuments,
    }): CancelablePromise<Documents> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns void
     * @throws ApiError
     */
    public static documentsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/documents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Прочитать документ
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns any No response body
     * @throws ApiError
     */
    public static documentsReadCreate({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/documents/{id}/read',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Получить количество непрочитанных документов
     * # Документы
     * Раздел: управление > Вкладка: документы
     * @returns DocCount
     * @throws ApiError
     */
    public static documentsUnreadCountRetrieve(): CancelablePromise<DocCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/documents/unread-count',
        });
    }

}
