import { flowResult } from "mobx";

export const catchFlowCancel = <T>(promise: Generator<T>) => {
  // @ts-ignore
  promise.catch((e: any) => {
    if (e.message === "FLOW_CANCELLED") {
      return;
    }

    throw e;
  });

  return flowResult(promise);
};
