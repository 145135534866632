import moment from "moment";
import {L} from "modules/L18n";
import {FormInstance} from "antd";


export const compareToEndDate = (form: FormInstance, endFieldName: string) => {
    return (rule: any, value: any, callback: any) => {
        const endDate = form.getFieldValue(endFieldName);
        if (value && endDate && moment(value).isAfter(moment(endDate))) {
            callback(L("REPORTS_STARTDATEAFTERENDDATE_ERROR"));
        } else {
            // Переустановить значение даты, чтобы убрать ошибку с поля
            form.setFieldsValue({endFieldName: endDate});
            callback();
        }
    };
}

export const compareToStartDate = (form: FormInstance, startFieldName: string) => {
    return (rule: any, value: any, callback: any) => {
        const startDate = form.getFieldValue(startFieldName);
        if (value && startDate && moment(value).isBefore(moment(startDate))) {
            callback(L("REPORTS_STARTDATEAFTERENDDATE_ERROR"));
        } else {
            form.setFieldsValue({startFieldName: startDate});
            callback();
        }
    };
}