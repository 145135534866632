import React from "react";
import {
  DocumentStoreType,
  GuardedObjectStoreType,
  HeadOfSecurityStoreType,
  ObjectGroupStoreType,
  ObjectStoreType,
  TaskStoreType,
  PlannedTaskStoreType,
  DocumentStore,
  GuardedObjectStore,
  HeadOfSecurityStore,
  ObjectGroupStore,
  ObjectStore,
  PlannedTaskStore,
  TaskStore,
  eventStore,
  EventStoreType,
  CoordinatesStore,
  CoordinatesStoreType,
  EmployeeStore,
  EmployeeStoreType,
  PromptReportStore,
  PromptReportStoreType,
  MessagesStore,
  MessagesStoreType,
  ActReportsStore,
  ActReportsStoreType,
  ContractStoreType,
  ContractStore,
  ContractorsStore,
  ContractorsStoreType,
  ActionLogsStore,
  ActionLogsStoreType,
  GuardPostStore,
  GuardPostStoreType,
  UserStore,
  UserStoreType,
  DailyReportsStore,
  DailyReportsStoreType,
  SecurityPostStore,
  SecurityPostStoreType,
  RegionalEmployeesStoreType,
  RegionalEmployeesStore,
} from "entities";
import { authenticationStore, AuthenticationStoreType } from "modules/Auth";
import { MainStore, MainStoreType } from "./store";

interface IContextValues {
  mainStore: MainStoreType;
  authenticationStore: AuthenticationStoreType;
  documentStore: DocumentStoreType;
  guardedObjectStore: GuardedObjectStoreType;
  headOfSecurityStore: HeadOfSecurityStoreType;
  objectGroupStore: ObjectGroupStoreType;
  objectStore: ObjectStoreType;
  taskStore: TaskStoreType;
  plannedTaskStore: PlannedTaskStoreType;
  eventStore: EventStoreType;
  coordStore: CoordinatesStoreType;
  employeeStore: EmployeeStoreType;
  promptReportStore: PromptReportStoreType;
  messageStore: MessagesStoreType;
  actReportsStore: ActReportsStoreType;
  contractStore: ContractStoreType;
  contractorsStore: ContractorsStoreType;
  actionLogsStore: ActionLogsStoreType;
  guardPostsStore: GuardPostStoreType;
  dailyReportsStore: DailyReportsStoreType;
  userStore: UserStoreType;
  securityPostStore: SecurityPostStoreType;
  regionalEmployeesStore: RegionalEmployeesStoreType;
}

export const stores: IContextValues = {
  authenticationStore,
  eventStore,
  mainStore: new MainStore(),
  documentStore: new DocumentStore(),
  guardedObjectStore: new GuardedObjectStore(),
  headOfSecurityStore: new HeadOfSecurityStore(),
  objectGroupStore: new ObjectGroupStore(),
  objectStore: new ObjectStore(),
  plannedTaskStore: new PlannedTaskStore(),
  taskStore: new TaskStore(),
  coordStore: new CoordinatesStore(),
  employeeStore: new EmployeeStore(),
  promptReportStore: new PromptReportStore(),
  messageStore: new MessagesStore(),
  actReportsStore: new ActReportsStore(),
  contractStore: new ContractStore(),
  contractorsStore: new ContractorsStore(),
  actionLogsStore: new ActionLogsStore(),
  guardPostsStore: new GuardPostStore(),
  userStore: new UserStore(),
  dailyReportsStore: new DailyReportsStore(),
  securityPostStore: new SecurityPostStore(),
  regionalEmployeesStore: new RegionalEmployeesStore(),
};

export const StoresContext = React.createContext<IContextValues>(stores);

export const StoresProvider = ({ children }: { children: React.ReactNode }) => (
  <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>
);
