export * from "api/models/Employees";
export * from "api/models/EditEmployees";
export * from "api/models/NewEmployees";

export enum _RoleTypeEnum {
   MONITORING_CENTER = "Сотрудник МЦ",
    SHIFT_SUPERVISOR = "Старший смены",
    REGIONAL_REPRESENTATIVE = "Региональный представитель",
    SHIFT_WORKER = "Сотрудник в смене",
}
