import { action, makeObservable, observable, override } from "mobx";

import { EventsService } from "api/services/EventsService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { BaseApiService } from "core/services/BaseApiService";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { downloadFile } from "utils/AsyncDownloadFile";
import { DetailEvent, Event } from "./PromptReport.interfaces";
import { EventTypeEnum } from "../Events";
import { stores } from "store";

interface IFilters extends IFiltersProps, IPaginatedProps {
  creationDateGte?: string;
  creationDateLte?: string;
  employee?: number;
  object?: number;
}

export class PromptReportStore extends BaseCrudStore<
  DetailEvent | Event,
  DetailEvent | Event,
  DetailEvent | Event,
  IFilters
> {
  @observable isPromptReportDownloading: boolean = false;

  constructor() {
    super(
      new BaseApiService({
        get: EventsService.eventsRetrieve,
        getAll: (props) =>
          EventsService.eventsList({
            ...props,
            types: [EventTypeEnum.PROMPT_REPORT, EventTypeEnum.INFO],
          }),
        delete: EventsService.eventsDestroy,
      })
    );

    makeObservable(this);
  }

  @action
  readPrompt = (act: Event) => {
    if (act.is_processed) {
      return;
    }

    stores.objectStore.changeNewsCount(
      act.obj,
      EventTypeEnum.PROMPT_REPORT,
      -1
    );

    const entity = this.entities.find((item) => item.id === act.id);

    if (entity) {
      entity.is_processed = true;
    }

    this.entities = [...this.entities];
  };

  @override
  async get(id: number) {
    const event = await super.get(id);
    return event;
  }

  @action
  async downloadPromptReport(report: Event) {
    this.isPromptReportDownloading = true;

    
    const file = await EventsService.eventsDocxRetrieve({ id: report.id });
    
    downloadFile(file, file.name || "PromptReport.docx");
    
    this.isPromptReportDownloading = false;

    const item = this.entities.find((item) => item.id === report.id);

    if (!report.is_processed) {
      stores.objectStore.changeNewsCount(
        report.obj,
        EventTypeEnum.PROMPT_REPORT,
        -1
      );
    }

    if (item) {
      item.is_processed = true;
      this.entities = [...this.entities];
    }
  }
}

export type PromptReportStoreType = PromptReportStore;
