import { MenuProps } from "antd";
import { RouteProps } from "react-router-dom";
import { invert } from "lodash-es";
import urlJoin from "url-join";

import { AppRouteTitle, IAppRoute } from "./Router.interfaces";
import { routers } from "./Router.config";
import { L } from "../../modules/L18n";
import { isGranted } from "../../utils/isGranted";
import { authenticationStore} from "modules/Auth";

type Path = Required<RouteProps>["path"];
type MenuItem = Required<MenuProps>["items"][number];

/* 
  Titles from routes
*/
const getRouteTitles = (
  routes: IAppRoute[] = [],
  rootPath?: Path
): Record<Path, AppRouteTitle> =>
  routes.reduce((acc, { title, path, children }) => {
    if (!path) {
      return acc;
    }

    const fullPath = urlJoin(rootPath || "/", path);

    return {
      ...acc,
      [fullPath]: title,
      ...getRouteTitles(children, fullPath),
    };
  }, {});

const ROUTE_TITLES = getRouteTitles(routers);

export const getPageTitle = (path: Path) =>
  [L(ROUTE_TITLES[path]), L("AppName")].join(" | ");

/* 
  Menu from router
*/
const TITLE_ROUTES = invert(ROUTE_TITLES);

const getPathByTitle = (title: AppRouteTitle | string): Path | null =>
  TITLE_ROUTES[title] || null;

const isRouteGroup = (route: IAppRoute) =>
  route.showInMenu && !!route.children;

const getMenuRoutes = (routes: IAppRoute[]): IAppRoute[] =>
  routes.reduce<IAppRoute[]>((acc, route) => {
    const { isLayout, showInMenu, permission, children } = route;

    if (permission &&  !permission(authenticationStore.user)) {
      return acc;
    }
    if (isRouteGroup(route)) {
      return [...acc, { ...route, children: getMenuRoutes(children || []) }];
    }

    if (isLayout || !showInMenu) {
      return [...acc, ...getMenuRoutes(children || [])];
    }

    return [...acc, route, ...getMenuRoutes(children || [])];
  }, []);

export const getMenuItemsFromRoutes = (routes: IAppRoute[]): MenuItem[] => {
  const menuRoutes = getMenuRoutes(routes);
  const menuItems = menuRoutes.map((route, i) => {
    const { title, icon, children } = route;
    const menuItemChildren = getMenuItemsFromRoutes(children || []);

    return {
      icon,
      key: getPathByTitle(title || "") || String(i),
      label: L(title),
      children: menuItemChildren.length !== 0 ? menuItemChildren : undefined,
    };
  });
  return menuItems;
};
