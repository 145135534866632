import { action, makeObservable, observable, override } from "mobx";

import { IFiltersProps, IPaginatedProps } from "core/types";
import { BaseApiService } from "core/services/BaseApiService";
import { Documents } from "./Documents.interfaces";
import { BaseCrudStore } from "../../core/stores/BaseCrudStore";
import { DocumentsService } from "../../api/services/DocumentsService";

interface IFilters extends IPaginatedProps, IFiltersProps {
  objectId?: number;
}

export class DocumentStore extends BaseCrudStore<
  Documents,
  Documents,
  Documents,
  IFilters
> {
  @observable acknowledgementList: any = [];

  constructor() {
    super(
      new BaseApiService({
        create: DocumentsService.documentsCreate,
        get: DocumentsService.documentsRetrieve,
        getAll: DocumentsService.documentsList,
        update: DocumentsService.documentsUpdate,
        delete: DocumentsService.documentsDestroy,
      })
    );

    makeObservable(this);
  }

  @action
  async getAcknowledgementListByDocumentId(documentId: Documents["id"]) {
    // let result = await service.getWithEmployeeAcknowledgementList(entity);
    this.acknowledgementList = [];
  }

  @override
  async createEntity() {
    this.entity = { id: 0 } as Documents;
    this.acknowledgementList = [];
  }
}

export type DocumentStoreType = DocumentStore;
