import { ChangeEventHandler } from "react";
import { Divider, Row, Typography, Comment } from "antd";

import { L } from "modules/L18n";
import { DetailEvent, EventFile } from "modules/Events";
import CommentList from "./CommentList";
import Editor from "./Editor";
import EventFileComponent from "./EventFile";

interface IEventProps {
  event: DetailEvent;
  comment: string;
  submitting: boolean;
  handleChange: ChangeEventHandler;
  handleSubmit: () => void;
}

const GenericEventComponent = ({
  event,
  comment,
  submitting,
  handleChange,
  handleSubmit,
}: IEventProps) => (
  <>
    <Row style={{ marginTop: 10, fontWeight: "bolder" }}>{event.text}</Row>
    <Divider />
    {!!event.files.length && (
      <>
        {event.files.map((file: EventFile) => (
          <EventFileComponent key={file.id} file={file} />
        ))}
        <Divider />
      </>
    )}
    <Row className={"greyBg"} style={{ flexDirection: "column" }}>
      <Typography.Title level={4} style={{ fontSize: 16 }}>
        {L("EVENTS_MODAL_COMMENTS_TITLE")}:
      </Typography.Title>
      <Comment
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={comment}
            buttonText={L("EVENTS_MODAL_COMMENTS_BUTTON")}
          />
        }
      />
      {!!event.comments && !!event.comments.length && (
        <CommentList comments={event.comments} />
      )}
    </Row>
  </>
);

export default GenericEventComponent;
