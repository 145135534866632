/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EditEmployees } from '../models/EditEmployees';
import type { EditMonitoringCenterEmployees } from '../models/EditMonitoringCenterEmployees';
import type { EditRegionalEmployees } from '../models/EditRegionalEmployees';
import type { Employees } from '../models/Employees';
import type { MonitoringCenterEmployees } from '../models/MonitoringCenterEmployees';
import type { NewEmployees } from '../models/NewEmployees';
import type { NewMonitoringCenterEmployees } from '../models/NewMonitoringCenterEmployees';
import type { NewRegionalEmployees } from '../models/NewRegionalEmployees';
import type { OnlineEmployees } from '../models/OnlineEmployees';
import type { PaginatedEmployeesList } from '../models/PaginatedEmployeesList';
import type { PaginatedMonitoringCenterEmployeesList } from '../models/PaginatedMonitoringCenterEmployeesList';
import type { PatchedEditEmployees } from '../models/PatchedEditEmployees';
import type { PatchedEditMonitoringCenterEmployees } from '../models/PatchedEditMonitoringCenterEmployees';
import type { PatchedEditRegionalEmployees } from '../models/PatchedEditRegionalEmployees';
import type { Roles } from '../models/Roles';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeesService {

    /**
     * @returns PaginatedEmployeesList
     * @throws ApiError
     */
    public static employeesList({
        limit,
        objectId,
        offset,
        ordering,
        roles,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        objectId?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * Роль сотрудника
         */
        roles?: Array<'Региональный представитель' | 'Сотрудник МЦ' | 'Сотрудник в смене' | 'Старший смены'>,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEmployeesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employees',
            query: {
                'limit': limit,
                'object_id': objectId,
                'offset': offset,
                'ordering': ordering,
                'roles': roles,
                'search': search,
            },
        });
    }

    /**
     * @deprecated
     * обновление сотрудника
     * роли: Сотрудник в смене, Старший смены
     * @returns Employees
     * @throws ApiError
     */
    public static employeesUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: EditEmployees,
    }): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * частичное обновление сотрудника
     * роли: Сотрудник в смене, Старший смены
     * @returns Employees
     * @throws ApiError
     */
    public static employeesPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedEditEmployees,
    }): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/employees/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @deprecated
     * @returns void
     * @throws ApiError
     */
    public static employeesDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/employees/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * роли: <br>    Сотрудник МЦ<br>    Старший смены<br>    Региональный представитель<br>    Сотрудник в смене
     * @returns Employees
     * @throws ApiError
     */
    public static employeesCreateCreate({
        requestBody,
    }: {
        requestBody: NewEmployees,
    }): CancelablePromise<Employees> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employees/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedMonitoringCenterEmployeesList
     * @throws ApiError
     */
    public static employeesMonitoringCenterEmployeeList({
        limit,
        objectId,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        objectId?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedMonitoringCenterEmployeesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employees/monitoring_center_employee',
            query: {
                'limit': limit,
                'object_id': objectId,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * обновление сотрудника
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesMonitoringCenterEmployeeUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: EditMonitoringCenterEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employees/monitoring_center_employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * частичное обновление сотрудника
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesMonitoringCenterEmployeePartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedEditMonitoringCenterEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/employees/monitoring_center_employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static employeesMonitoringCenterEmployeeDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/employees/monitoring_center_employee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesMonitoringCenterEmployeeCreateCreate({
        requestBody,
    }: {
        requestBody: NewMonitoringCenterEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employees/monitoring_center_employee/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Работники онлайн с координатами
     * @returns OnlineEmployees
     * @throws ApiError
     */
    public static employeesOnlineRetrieve(): CancelablePromise<OnlineEmployees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employees/online',
        });
    }

    /**
     * обновление сотрудника
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesRegionalEmployeeUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: EditRegionalEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/employees/regional_employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * частичное обновление сотрудника
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesRegionalEmployeePartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedEditRegionalEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/employees/regional_employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static employeesRegionalEmployeeDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/employees/regional_employee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * роли: Сотрудник МЦ, Региональный представитель
     * @returns MonitoringCenterEmployees
     * @throws ApiError
     */
    public static employeesRegionalEmployeeCreateCreate({
        requestBody,
    }: {
        requestBody: NewRegionalEmployees,
    }): CancelablePromise<MonitoringCenterEmployees> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/employees/regional_employee/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Роли работников
     * @returns Roles
     * @throws ApiError
     */
    public static employeesRolesRetrieve(): CancelablePromise<Roles> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/employees/roles',
        });
    }

}
