/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEventWithFile } from '../models/CreateEventWithFile';
import type { DetailEvent } from '../models/DetailEvent';
import type { EventComment } from '../models/EventComment';
import type { EventFile } from '../models/EventFile';
import type { PaginatedEventCommentList } from '../models/PaginatedEventCommentList';
import type { PaginatedEventFileList } from '../models/PaginatedEventFileList';
import type { PaginatedEventListList } from '../models/PaginatedEventListList';
import type { PatchedCreateEventWithFile } from '../models/PatchedCreateEventWithFile';
import type { PatchedDetailEvent } from '../models/PatchedDetailEvent';
import type { PatchedEventComment } from '../models/PatchedEventComment';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EventsService {

    /**
     * @returns PaginatedEventListList
     * @throws ApiError
     */
    public static eventsList({
        creationDateGte,
        creationDateLte,
        employee,
        limit,
        object,
        offset,
        ordering,
        search,
        types,
    }: {
        creationDateGte?: string,
        creationDateLte?: string,
        employee?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        object?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
        types?: Array<'Доклад' | 'Оперативное донесение' | 'Оперативное информирование' | 'Тревога'>,
    }): CancelablePromise<PaginatedEventListList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/',
            query: {
                'creation_date_gte': creationDateGte,
                'creation_date_lte': creationDateLte,
                'employee': employee,
                'limit': limit,
                'object': object,
                'offset': offset,
                'ordering': ordering,
                'search': search,
                'types': types,
            },
        });
    }

    /**
     * @returns DetailEvent
     * @throws ApiError
     */
    public static eventsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<DetailEvent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns DetailEvent
     * @throws ApiError
     */
    public static eventsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedDetailEvent,
    }): CancelablePromise<DetailEvent> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static eventsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/events/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns binary
     * @throws ApiError
     */
    public static eventsDocxRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/{id}/docx/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedEventCommentList
     * @throws ApiError
     */
    public static eventsCommentsList({
        event,
        limit,
        offset,
        ordering,
        search,
    }: {
        event?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEventCommentList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/comments/',
            query: {
                'event': event,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns EventComment
     * @throws ApiError
     */
    public static eventsCommentsCreate({
        requestBody,
    }: {
        requestBody: EventComment,
    }): CancelablePromise<EventComment> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/comments/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventComment
     * @throws ApiError
     */
    public static eventsCommentsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<EventComment> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/comments/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventComment
     * @throws ApiError
     */
    public static eventsCommentsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: EventComment,
    }): CancelablePromise<EventComment> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/events/comments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventComment
     * @throws ApiError
     */
    public static eventsCommentsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedEventComment,
    }): CancelablePromise<EventComment> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/comments/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static eventsCommentsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/events/comments/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Добавление события с типом - Тревога
     * @returns CreateEventWithFile
     * @throws ApiError
     */
    public static eventsCreateAlarmCreate({
        requestBody,
    }: {
        requestBody: CreateEventWithFile,
    }): CancelablePromise<CreateEventWithFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/create/alarm/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CreateEventWithFile
     * @throws ApiError
     */
    public static eventsCreateAlarmPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedCreateEventWithFile,
    }): CancelablePromise<CreateEventWithFile> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/events/create/alarm/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Добавление события с типом - Оперативное информирование
     * @returns CreateEventWithFile
     * @throws ApiError
     */
    public static eventsCreatePromptInfoCreate({
        requestBody,
    }: {
        requestBody: CreateEventWithFile,
    }): CancelablePromise<CreateEventWithFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/create/prompt_info/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Добавление события с типом - Оперативное донесение
     * @returns CreateEventWithFile
     * @throws ApiError
     */
    public static eventsCreatePromptReportCreate({
        requestBody,
    }: {
        requestBody: CreateEventWithFile,
    }): CancelablePromise<CreateEventWithFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/create/prompt_report/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Добавление события с типом - Доклад
     * @returns CreateEventWithFile
     * @throws ApiError
     */
    public static eventsCreateReportCreate({
        requestBody,
    }: {
        requestBody: CreateEventWithFile,
    }): CancelablePromise<CreateEventWithFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/create/report/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedEventFileList
     * @throws ApiError
     */
    public static eventsFilesList({
        event,
        limit,
        offset,
        ordering,
        search,
    }: {
        event?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEventFileList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/files/',
            query: {
                'event': event,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns EventFile
     * @throws ApiError
     */
    public static eventsFilesCreate({
        requestBody,
    }: {
        requestBody: EventFile,
    }): CancelablePromise<EventFile> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/events/files/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EventFile
     * @throws ApiError
     */
    public static eventsFilesRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<EventFile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/events/files/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EventFile
     * @throws ApiError
     */
    public static eventsFilesUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: EventFile,
    }): CancelablePromise<EventFile> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/events/files/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static eventsFilesDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/events/files/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
