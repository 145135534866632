import {
  BaseDeletePoint,
  BaseGetAllPoint,
  BaseGetPoint,
  BasePostPoint,
  BaseUpdatePoint,
  IIdentifiable,
} from "core/types";

export class BaseApiService<
  T extends IIdentifiable,
  TCreate = T,
  TUpdate extends IIdentifiable = T,
> {
  get?: BaseGetPoint<T>;
  getAll?: BaseGetAllPoint<T>;
  create?: BasePostPoint<T, TCreate>;
  update?: BaseUpdatePoint<TUpdate>;
  delete?: BaseDeletePoint;

  constructor(
    private _api: {
      get?: BaseGetPoint<T>;
      getAll?: BaseGetAllPoint<T>;
      create?: BasePostPoint<T, TCreate>;
      update?: BaseUpdatePoint<TUpdate>;
      delete?: BaseDeletePoint;
    }
  ) {
    this.get = this._api?.get;
    this.getAll = this._api?.getAll;
    this.create = this._api?.create;
    this.update = this._api?.update;
    this.delete = this._api?.delete;
  }
}
