import { notification } from "antd";
import { reaction } from "mobx";
// @ts-ignore
import { SSE } from "sse.js";

import { authenticationStore } from "modules/Auth";
import { eventStore } from "entities";
import { CONFIG } from "config";
import { Act, ApiError, Report, UserService } from "api";
import { stores } from "store/store.context";

import {
  SSEMessage,
  EventTargetTyped,
  EventTypeEnum,
  DetailEvent,
} from "./Events.interfaces";
import act from "./assets/act.mp3";
import sos from "./assets/siren.mp3";
import newEvent from "./assets/event.mp3";
import newMessage from "./assets/message.mp3";
import alarmSuppressionSiren from "./assets/alarm-suppression-siren.mp3";

const beepAct = new Audio(act);
const beepSos = new Audio(sos);
const beepNewEvent = new Audio(newEvent);
const beepNewMessage = new Audio(newMessage);
const beepAlarmSuppressionSiren = new Audio(alarmSuppressionSiren);

const playSound = (sound: HTMLAudioElement) => {
  sound.play().catch(console.log);
};

const handleReports = (e: any) => {
  playSound(beepAlarmSuppressionSiren);

  const event = e.detail as DetailEvent;

  notification.open({
    message: `${event.event_type}: ${event.object_name}`,
    description: event.text,
    duration: 0,
  });

  stores.objectStore.changeNewsCount(
    event.obj,
    event.event_type as unknown as EventTypeEnum,
    1
  );
};

const refreshEventStore = (event: DetailEvent) => {
  const isSameObject = stores.objectStore.selectedObject?.id === event.obj;

  const isEventSiderOpen =
    window.location.pathname.startsWith("/objects/monitoring") &&
    stores.mainStore.eventSidebarOpen;

  const isEventTab = stores.mainStore.activeManageTabKey === "events";

  if (!isSameObject) {
    return;
  }

  if (isEventSiderOpen || isEventTab) {
    stores.eventStore.refresh();
  }
};

const handleInfo = (e: any) => {
  playSound(beepNewEvent);

  const info = e.detail as DetailEvent;

  stores.objectStore.changeNewsCount(info.obj, EventTypeEnum.INFO, 1);

  refreshEventStore(info);
};

const handleAlarm = (e: any) => {
  playSound(beepSos);

  const alert = e.detail as DetailEvent;

  eventStore.entity = alert;
  eventStore.sosComment = "";
  eventStore.isViewEventModalVisible = true;

  stores.objectStore.changeNewsCount(alert.obj, EventTypeEnum.ALARM, 1);

  refreshEventStore(alert);
};

const handleMessage = (e: any) => {
  playSound(beepNewMessage);

  const message = e.detail;

  stores.messageStore.receiveMessage(message);

  if (message.new) {
    stores.objectStore.changeNewsCount(
      message.object_id,
      EventTypeEnum.MESSAGE,
      1
    );
  }
};

const handleAct = (e: any) => {
  playSound(beepAct);
  const act = e.detail as Act;
  stores.objectStore.changeNewsCount(act.object_id, EventTypeEnum.ACT, 1);
};

const taskReport = (e: any) => {
  playSound(beepNewMessage);

  const report = e.detail as Report;

  stores.objectStore.changeNewsCount(
    // @ts-ignore
    report.object_id,
    EventTypeEnum.TASK_REPORT,
    1
  );
};

export const eventService: EventTargetTyped = new EventTarget();

eventService.addEventListener(EventTypeEnum.PROMPT_REPORT, handleReports);
eventService.addEventListener(EventTypeEnum.REPORT, handleReports);
eventService.addEventListener(EventTypeEnum.INFO, handleInfo);
eventService.addEventListener(EventTypeEnum.ALARM, handleAlarm);
eventService.addEventListener(EventTypeEnum.MESSAGE, handleMessage);
eventService.addEventListener(EventTypeEnum.ACT, handleAct);
eventService.addEventListener(EventTypeEnum.TASK_REPORT, taskReport);

class SSEService {
  connection: typeof SSE;
  observable: EventTarget;

  constructor(observable: EventTarget) {
    this.observable = observable;

    reaction(
      () => ({
        user: authenticationStore.user,
        token: authenticationStore.access,
      }),
      ({ user, token }) => {
        this.connection?.close();

        if (user && token) {
          this.init();
        }
      }
    );
  }

  private handleMessage = (e: SSEMessage) => {
    let eventInit: CustomEventInit = {};
    let data: any;

    if (e.id === null) {
      return;
    }

    console.log("SSE: recieved message");

    if (e.data) {
      try {
        data = JSON.parse(e.data);
        eventInit.detail = data?.data || data;

        console.log(eventInit.detail);

        this.observable.dispatchEvent(
          // TODO: привести получаемый ивент к общему виду
          // @ts-ignore
          new CustomEvent(data.type || data.event_type, eventInit)
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  private handleError = async (e: Error) => {
    const token = authenticationStore.access;

    if (!token) {
      console.log("SSE: connection closed, token is not defined");
      return;
    }

    console.log("SSE: connection closed, try to reconnect...");

    try {
      await UserService.userTokenVerifyCreate({ requestBody: { token } });
      this.init();
    } catch (e: any) {
      if (e instanceof ApiError && e.status === 401) {
        await authenticationStore.refreshTokenPair();
        return;
      }

      if (e instanceof ApiError && e.status === 403) {
        console.log("SSE: connection closed, token is not valid");
        await authenticationStore.logout();
        return;
      }

      setTimeout(this.init, 5000);
    }
  };

  private handleAbort = () => {
    console.log("SSE: connection closed");
  };

  init = () => {
    const { access: token, user } = authenticationStore;

    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }

    this.connection = new SSE(
      `${CONFIG.apiBaseUrl}/event_stream/messages/${user?.employee?.id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    this.connection.addEventListener("message", this.handleMessage);
    this.connection.addEventListener("error", this.handleError);
    this.connection.addEventListener("abort", this.handleAbort);

    this.connection.stream();

    console.log("SSE: connection open");
  };
}

new SSEService(eventService);
