import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import _ from "lodash";

import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { IIdentifiable } from "core/types";
import { catchFlowCancel } from "utils";

const DropdownRender = observer(
  ({
    menu,
    store,
  }: {
    menu: React.ReactElement;
    store: BaseCrudStore<any>;
  }) => {
    return <Spin spinning={store.isEntitiesLoading}>{menu}</Spin>;
  }
);

type SelectWithStoreType = <T extends IIdentifiable>({
  store,
  value,
  initialValue,
  getOption,
  ...props
}: {
  store: BaseCrudStore<T, any, any>;
  initialValue?: T | T[];
  getOption?: (entity: T) => DefaultOptionType;
} & SelectProps) => React.ReactElement;

const SelectWithStore: SelectWithStoreType = ({
  store,
  initialValue,
  getOption,
  ...props
}) => {
  const { entities } = store;

  const [search, setSearch] = useState<string>("");

  if (!getOption) {
    getOption = (entity: any) => ({ label: entity.name, value: entity.id });
  }

  const options: DefaultOptionType[] = [...entities]?.map(getOption);

  if (initialValue) {
    if (Array.isArray(initialValue)) {
      options.unshift(
        ...initialValue
          .map(getOption)
          .filter((v) => !options.find((o) => o.value === v.value))
      );
    } else {
      options.unshift(getOption(initialValue));
    }
  }

  const debouncedSearch = useCallback(
    _.debounce(setSearch, 1000, {
      leading: false,
      trailing: true,
    }),
    [store]
  );

  useEffect(() => {
    store.clear();
    store.setLimit(30);
    store.setFilters({ search, ordering: "name" }, true);
  }, [store, search]);

  useEffect(() => {
    return catchFlowCancel(store.loadMore()).cancel;
  }, [store, store.filters]);

  return (
    <Select
      showSearch
      allowClear={true}
      options={options}
      style={{ width: "100%" }}
      onSearch={(s) => {
        debouncedSearch(s);
      }}
      onPopupScroll={(e) => {
        const container = e.target as HTMLElement;
        const elements = container.firstChild as HTMLElement;

        if (
          elements?.getBoundingClientRect().bottom -
            container.getBoundingClientRect().bottom ===
          0
        ) {
          if (store.isEntitiesLoading) return;
          store.loadMore();
        }
      }}
      filterOption={false}
      dropdownRender={(menu) => {
        return <DropdownRender menu={menu} store={store} />;
      }}
      {...props}
    />
  );
};

export default observer(SelectWithStore);
