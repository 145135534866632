enum _EventTypeEnum {
  TASK_REPORT = "Отчет по задаче",
  PROMPT_REPORT = "Оперативное донесение",
  INFO = "Оперативное информирование",
  REPORT = "Доклад",
  ACT = "Акт",
  ALARM = "Тревога",
}

export const EventTypeEnum = _EventTypeEnum as Record<
  keyof typeof _EventTypeEnum,
  any
>;

export * from "api/models/EventComment";
export * from "api/models/EventFile";
export * from "api/models/DetailEvent";
export * from "api/models/PatchedDetailEvent";

export type { EventList as Event } from "api/models/EventList";
