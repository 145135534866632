/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractEmployees } from '../models/ContractEmployees';
import type { Contractors } from '../models/Contractors';
import type { Contracts } from '../models/Contracts';
import type { CreateContractors } from '../models/CreateContractors';
import type { CreateContracts } from '../models/CreateContracts';
import type { EmployeeCategory } from '../models/EmployeeCategory';
import type { PaginatedContractEmployeesList } from '../models/PaginatedContractEmployeesList';
import type { PaginatedContractorsList } from '../models/PaginatedContractorsList';
import type { PaginatedContractsList } from '../models/PaginatedContractsList';
import type { PaginatedEmployeeCategoryList } from '../models/PaginatedEmployeeCategoryList';
import type { UpdateContracts } from '../models/UpdateContracts';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContractsService {

    /**
     * @returns PaginatedContractsList
     * @throws ApiError
     */
    public static contractsList({
        limit,
        object,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        object?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedContractsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/',
            query: {
                'limit': limit,
                'object': object,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns CreateContracts
     * @throws ApiError
     */
    public static contractsCreate({
        requestBody,
    }: {
        requestBody: CreateContracts,
    }): CancelablePromise<CreateContracts> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contracts/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Contracts
     * @throws ApiError
     */
    public static contractsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns UpdateContracts
     * @throws ApiError
     */
    public static contractsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: UpdateContracts,
    }): CancelablePromise<UpdateContracts> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contracts/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static contractsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contracts/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedContractEmployeesList
     * @throws ApiError
     */
    public static contractsContractEmployeesList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedContractEmployeesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/contract_employees',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns ContractEmployees
     * @throws ApiError
     */
    public static contractsContractEmployeesCreate({
        requestBody,
    }: {
        requestBody: ContractEmployees,
    }): CancelablePromise<ContractEmployees> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contracts/contract_employees',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ContractEmployees
     * @throws ApiError
     */
    public static contractsContractEmployeesRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<ContractEmployees> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/contract_employees/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ContractEmployees
     * @throws ApiError
     */
    public static contractsContractEmployeesUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: ContractEmployees,
    }): CancelablePromise<ContractEmployees> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contracts/contract_employees/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static contractsContractEmployeesDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contracts/contract_employees/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedContractorsList
     * @throws ApiError
     */
    public static contractsContractorsList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedContractorsList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/contractors/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns CreateContractors
     * @throws ApiError
     */
    public static contractsContractorsCreate({
        requestBody,
    }: {
        requestBody: CreateContractors,
    }): CancelablePromise<CreateContractors> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contracts/contractors/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Contractors
     * @throws ApiError
     */
    public static contractsContractorsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Contractors> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/contractors/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Contractors
     * @throws ApiError
     */
    public static contractsContractorsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Contractors,
    }): CancelablePromise<Contractors> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contracts/contractors/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static contractsContractorsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contracts/contractors/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedEmployeeCategoryList
     * @throws ApiError
     */
    public static contractsEmployeeCategoryList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedEmployeeCategoryList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/employee_category/',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns EmployeeCategory
     * @throws ApiError
     */
    public static contractsEmployeeCategoryCreate({
        requestBody,
    }: {
        requestBody: EmployeeCategory,
    }): CancelablePromise<EmployeeCategory> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/contracts/employee_category/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns EmployeeCategory
     * @throws ApiError
     */
    public static contractsEmployeeCategoryRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<EmployeeCategory> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/contracts/employee_category/{id}/',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns EmployeeCategory
     * @throws ApiError
     */
    public static contractsEmployeeCategoryUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: EmployeeCategory,
    }): CancelablePromise<EmployeeCategory> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/contracts/employee_category/{id}/',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static contractsEmployeeCategoryDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/contracts/employee_category/{id}/',
            path: {
                'id': id,
            },
        });
    }

}
