/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DetailGuardPost } from '../models/DetailGuardPost';
import type { DetailPostEmployee } from '../models/DetailPostEmployee';
import type { Duty } from '../models/Duty';
import type { GuardPost } from '../models/GuardPost';
import type { PaginatedDutyList } from '../models/PaginatedDutyList';
import type { PaginatedGuardPostList } from '../models/PaginatedGuardPostList';
import type { PaginatedPostEmployeeList } from '../models/PaginatedPostEmployeeList';
import type { PatchedDuty } from '../models/PatchedDuty';
import type { PatchedGuardPost } from '../models/PatchedGuardPost';
import type { PatchedPostEmployee } from '../models/PatchedPostEmployee';
import type { PostEmployee } from '../models/PostEmployee';
import type { SetDuties } from '../models/SetDuties';
import type { SetEmployees } from '../models/SetEmployees';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ScheduleService {

    /**
     * @returns PaginatedDutyList
     * @throws ApiError
     */
    public static scheduleDutyList({
        dateFrom,
        dateTo,
        limit,
        offset,
        ordering,
        postEmployeeIds,
        search,
    }: {
        dateFrom?: string,
        dateTo?: string,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        postEmployeeIds?: Array<number>,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedDutyList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/duty',
            query: {
                'date_from': dateFrom,
                'date_to': dateTo,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'post_employee_ids': postEmployeeIds,
                'search': search,
            },
        });
    }

    /**
     * @returns Duty
     * @throws ApiError
     */
    public static scheduleDutyCreate({
        requestBody,
    }: {
        requestBody: Duty,
    }): CancelablePromise<Duty> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedule/duty',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Duty
     * @throws ApiError
     */
    public static scheduleDutyRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Duty> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/duty/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns Duty
     * @throws ApiError
     */
    public static scheduleDutyUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Duty,
    }): CancelablePromise<Duty> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/schedule/duty/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Duty
     * @throws ApiError
     */
    public static scheduleDutyPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedDuty,
    }): CancelablePromise<Duty> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/schedule/duty/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static scheduleDutyDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/schedule/duty/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PaginatedPostEmployeeList
     * @throws ApiError
     */
    public static scheduleEmployeeList({
        limit,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPostEmployeeList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/employee',
            query: {
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns PostEmployee
     * @throws ApiError
     */
    public static scheduleEmployeeCreate({
        requestBody,
    }: {
        requestBody: PostEmployee,
    }): CancelablePromise<PostEmployee> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedule/employee',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DetailPostEmployee
     * @throws ApiError
     */
    public static scheduleEmployeeRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<DetailPostEmployee> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/employee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns PostEmployee
     * @throws ApiError
     */
    public static scheduleEmployeeUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: PostEmployee,
    }): CancelablePromise<PostEmployee> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/schedule/employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PostEmployee
     * @throws ApiError
     */
    public static scheduleEmployeePartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedPostEmployee,
    }): CancelablePromise<PostEmployee> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/schedule/employee/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static scheduleEmployeeDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/schedule/employee/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Обновление списка работников поста
     * @returns DetailPostEmployee
     * @throws ApiError
     */
    public static scheduleEmployeeSetDutiesCreate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: SetDuties,
    }): CancelablePromise<DetailPostEmployee> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedule/employee/{id}/set_duties',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns PaginatedGuardPostList
     * @throws ApiError
     */
    public static schedulePostList({
        employeeId,
        limit,
        object,
        offset,
        ordering,
        search,
    }: {
        employeeId?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        object?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedGuardPostList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/post',
            query: {
                'employee_id': employeeId,
                'limit': limit,
                'object': object,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * @returns GuardPost
     * @throws ApiError
     */
    public static schedulePostCreate({
        requestBody,
    }: {
        requestBody: GuardPost,
    }): CancelablePromise<GuardPost> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedule/post',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns DetailGuardPost
     * @throws ApiError
     */
    public static schedulePostRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<DetailGuardPost> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/schedule/post/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns GuardPost
     * @throws ApiError
     */
    public static schedulePostUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: GuardPost,
    }): CancelablePromise<GuardPost> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/schedule/post/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns GuardPost
     * @throws ApiError
     */
    public static schedulePostPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedGuardPost,
    }): CancelablePromise<GuardPost> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/schedule/post/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static schedulePostDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/schedule/post/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Обновление списка работников поста
     * @returns DetailGuardPost
     * @throws ApiError
     */
    public static schedulePostSetEmployeesCreate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: SetEmployees,
    }): CancelablePromise<DetailGuardPost> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/schedule/post/{id}/set_employees',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
