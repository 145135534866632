import { makeAutoObservable } from "mobx";

export class MainStore {
  activeManageTabKey?: string;
  sidebarCollapsed: boolean = true;
  eventSidebarOpen: boolean = false;
  onlineCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  onManageTabChange = async (currentTabKey: string) => {
    this.activeManageTabKey = currentTabKey;
  };

  toggleEventSidebar = (value: boolean) => {
    if (typeof value === "boolean") {
      this.eventSidebarOpen = value;
    } else {
      this.eventSidebarOpen = !this.eventSidebarOpen;
    }
  };

  setOnlineCount(n: number) {
    this.onlineCount = n;
  }
}

export type MainStoreType = MainStore;
