import { action, makeObservable, observable } from "mobx";

import { EmployeesService } from "api";
import { Employees, GuardPost, guardPostsService } from "entities";
import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { LogsService } from "api/services/LogsService";
import { Logs } from "./ActionLogs.interfaces";
import { IFiltersProps, IPaginatedProps } from "core/types";

interface IFilters extends IFiltersProps, IPaginatedProps {
  dateGte?: string;
  dateLte?: string;
  employee?: number;
  object?: number;
  postName?: string;
}

export class ActionLogsStore extends BaseCrudStore<Logs, Logs, Logs, IFilters> {
  @observable employees: Employees[] = [];
  @observable isEmployeesLoading: boolean = false;

  @observable guardPosts: GuardPost[] = [];
  @observable isGuardPostsLoading: boolean = false;

  constructor() {
    super(
      new BaseApiService({
        create: LogsService.logsCreate,
        get: LogsService.logsRetrieve,
        getAll: LogsService.logsList,
        update: LogsService.logsUpdate,
        delete: LogsService.logsDestroy,
      })
    );

    makeObservable(this);
  }

  @action
  async loadEmployees(objectId: number) {
    this.isEmployeesLoading = true;
    let { results } = await EmployeesService.employeesList({ objectId, limit: 9999999 });
    this.employees = results || [];
    this.isEmployeesLoading = false;
  }

  @action
  async loadGuardPosts(objectId: number) {
    this.isGuardPostsLoading = true;
    let { results } = await guardPostsService.getAll!({
      object: objectId,
      limit: 9999999,
    });
    this.guardPosts = results || [];
    this.isGuardPostsLoading = false;
  }
}

export type ActionLogsStoreType = ActionLogsStore;
