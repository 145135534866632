import { action, flow, makeObservable, observable } from "mobx";

import {
  IIdentifiable,
  IFiltersProps,
  IServerFormErrors,
  IPaginatedProps,
} from "core/types";
import { removeEmptyFlat } from "utils";
import { BaseApiService } from "core/services/BaseApiService";

export class BaseCrudStore<
  T extends IIdentifiable,
  TCreate = T,
  TUpdate extends IIdentifiable = T,
  TFilters extends IFiltersProps = IFiltersProps & IPaginatedProps
> {
  @observable isEntitiesLoading: boolean = false;
  @observable errors: IServerFormErrors = { global: null, fields: null };
  @observable count: number = 0;
  @observable hasMore: boolean = true;
  @observable entities: T[] = [];
  @observable entity: T | null = null;

  @observable filters: TFilters = {
    ordering: "id",
    search: "",
  };

  constructor(private service: BaseApiService<T, TCreate, TUpdate>) {
    this.service = service;
    makeObservable(this);
  }

  @action
  async create(entity: TCreate) {
    return this.request(this.service.create, { requestBody: entity });
  }

  @action
  async createEntity(entity?: Partial<TCreate>) {
    this.entity = { ...entity, id: 0 } as T;
  }

  @action
  async update(entity: TUpdate) {
    const updatedEntity = await this.request(this.service.update, {
      id: entity.id,
      requestBody: entity,
    });

    if (!updatedEntity) {
      return;
    }

    const storeEntity = this.entities.find((x: T) => x.id === updatedEntity.id);

    if (storeEntity) {
      Object.assign(storeEntity, updatedEntity);
    }

    if (this.entity) {
      Object.assign(this.entity, updatedEntity);
    }

    return storeEntity;
  }

  @action
  async delete(entityId: IIdentifiable["id"]) {
    if (!this.service.delete) {
      throw new Error("Not implemented!");
    }
    await this.service.delete({ id: entityId });
    this.entities = this.entities.filter((entity: T) => entity.id !== entityId);
  }

  @action
  async get(entityId: IIdentifiable["id"]) {
    if (!this.service.get) {
      throw new Error("Not implemented!");
    }
    const result = await this.service.get({ id: entityId });
    this.entity = result;
    return result;
  }

  @action
  async getAll(filters?: TFilters) {
    const results = await this.getBulk(filters);
    this.entities = results || [];
    return results;
  }

  @observable limit: number = 100;
  @observable offset: number = 0;

  @flow.bound
  *loadMore(clear?: boolean) {
    if (clear === true) {
      this.clear();
    }

    if (!this.hasMore) {
      return [];
    }

    const results = yield this.getBulk({
      limit: this.limit,
      offset: this.offset,
    });

    this.entities = clear ? results! : [...this.entities, ...results!];
    this.offset += this.limit;

    return results;
  }

  @action
  refresh = () => {
    if (!this.isEntitiesLoading) {
      return this.getAll({ offset: 0, limit: this.offset });
    }
  };

  @action
  clear = () => {
    this.offset = 0;
    this.entities = [];
    this.count = 0;
    this.hasMore = true;
    this.isEntitiesLoading = false;
  };

  @action.bound
  clearEntity() {
    this.entity = null;
  }

  @action
  setLimit(limit: number) {
    this.limit = limit;
  }

  @action
  setOffset(offset: number) {
    this.offset = offset;
  }

  @action
  setFilters(filters: TFilters, merge: boolean = false) {
    if (merge) {
      this.filters = {
        ...this.filters,
        ...filters,
      };
      return;
    }

    this.filters = filters;
  }

  @action
  resetErrors() {
    this.errors.global = null;
    this.errors.fields = null;
  }

  @action
  private async request(serviceMethod: any, props: any) {
    if (!serviceMethod) {
      throw new Error("Not implemented!");
    }

    this.resetErrors();

    try {
      return await serviceMethod(props);
    } catch (e: any) {
      const errors = e.body;

      if (Array.isArray(errors)) {
        this.errors.global = errors;
      } else {
        this.errors.fields = errors;
      }
    }
  }

  @action
  private async getBulk(filters?: TFilters) {
    if (!this.service.getAll) {
      throw new Error("Not implemented!");
    }

    this.isEntitiesLoading = true;

    const filtersWithoutEmpty = removeEmptyFlat({
      ...this.filters,
      ...filters,
    });

    const { results, count, next } = await this.service.getAll(
      filtersWithoutEmpty
    );

    this.count = count || 0;
    this.hasMore = !!next;
    this.isEntitiesLoading = false;

    return results;
  }
}
