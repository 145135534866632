import { useEffect, useMemo, useState } from "react";
import { Form, Modal, Row, Col, Divider, Spin, Tabs, TabsProps } from "antd";
import { useForm, FormInstance } from "antd/lib/form/Form";
import { CirclePicker, ColorResult } from "react-color";

// import CheckboxGroup from "antd/lib/checkbox/Group";

import { L } from "modules/L18n";
// import { isGranted } from "utils/isGranted";
import { DetailObject } from "entities";
import Avatar from "./elements/Avatar";
import ObjectsGeneralTab from "./elements/ObjectsGeneralTab";
import { getLink } from "utils";

export interface ICreateOrUpdateObjectProps {
  formRef: (ref: FormInstance) => void;
  obj: DetailObject | null;
  open: boolean;
  // availableTenants: any[];
  loading?: boolean;
  onOk: () => void;
  onCancel: () => void;
}

enum OBJECT_MANAGE_TABS {
  general = "general",
  access = "access",
}

const CreateOrUpdateObject = ({
  formRef,
  obj,
  open,
  loading,
  // availableTenants,
  onCancel,
  onOk,
}: ICreateOrUpdateObjectProps) => {
  const [activeTab, setActiveTab] = useState<string>(
    OBJECT_MANAGE_TABS.general
  );

  const [form] = useForm();

  // const tenants = availableTenants?.map((tenant) => ({
  //   label: tenant.name,
  //   value: tenant.id,
  //   key: tenant.id.toString(),
  //   title: tenant.name,
  // }));

  const tabs = useMemo<TabsProps["items"]>(
    () => [
      {
        key: OBJECT_MANAGE_TABS.general,
        label: L("OBJECT_GENERAL_INFO"),
        children: <ObjectsGeneralTab obj={obj} />,
      },
      // {
      //   key: OBJECT_MANAGE_TABS.access,
      //   disabled: !isGranted("Objects.EditAccessPermissions"),
      //   label: L("OBJECT_ACCESS"),
      //   children: (
      //     <Form.Item name="allowedTenants" valuePropName="value">
      //       <CheckboxGroup options={tenants} />
      //     </Form.Item>
      //   ),
      // },
    ],
    [obj]
  );

  useEffect(() => {
    formRef(form);
  }, [form, formRef]);

  useEffect(() => {
    if (obj) {
      form.resetFields();
      form.setFieldsValue({
        ...obj,
        regional_representative: obj?.employees?.map((employee) => employee.id),
        contract: obj?.contract?.id,
        group: obj?.group?.id,
        so_executor: obj?.so_executor?.id,
        head_of_security_id: obj?.head_of_security_id?.id,
      });
    }
  }, [form, obj]);

  if (!obj) {
    return null;
  }

  return (
    <Modal
      width={700}
      open={open}
      cancelText={L("MODAL_CANCEL_BUTTON")}
      okText={L("Save")}
      onCancel={onCancel}
      onOk={onOk}
      maskClosable={false}
    >
      <h1 style={{ fontSize: 26, textAlign: "center" }}>
        {L("OBJECT_MODAL_TITLE")}
      </h1>
      <Spin spinning={loading}>
        <Form form={form}>
          <Row align="top" style={{ padding: "40px" }}>
            <Col>
              <Form.Item name="photo">
                <Avatar
                  photoUrl={getLink(obj.photo_url)}
                  onUpload={(file: Blob) => {
                    form.setFieldValue("photo", file);
                  }}
                />
              </Form.Item>
            </Col>
            <Col style={{ marginLeft: 30 }}>
              <Form.Item name="color">
                <CirclePicker
                  color={obj.color || ""}
                  onChangeComplete={(color: ColorResult) =>
                    form.setFieldValue("color", color.hex)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Tabs
            items={tabs}
            defaultActiveKey={activeTab}
            onChange={setActiveTab}
            size={"small"}
            tabBarGutter={64}
          />
        </Form>
      </Spin>
    </Modal>
  );
};

export default CreateOrUpdateObject;
