import { useRoutes } from "react-router-dom";
import { wrapUseRoutes } from "@sentry/react";

import { routers } from "./Router.config";

const useSentryRoutes = wrapUseRoutes(useRoutes);

const Router = () => {
  return useSentryRoutes(routers);
};

export default Router;
