import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import { capitalize } from "lodash-es";
import moment from "moment";

const Time = () => {
  const [date, setDate] = useState(moment());
  const [tick, setTick] = useState(true);

  useEffect(() => {
    const updateTime = () => {
      setDate(moment());
      setTick((tick) => !tick);
    };

    const intervalId = setInterval(updateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Row>
      <Col style={{ fontSize: 36, color: "#19181B" }}>
        {date.format("HH")}
        <span style={{ color: tick ? "white" : "grey" }}>:</span>
        {date.format("mm")}
      </Col>
      <Col style={{ marginLeft: 5, paddingTop: 16, paddingBottom: 16 }}>
        <Row style={{ lineHeight: "16px", color: "#19181B", opacity: "0.4" }}>
          {capitalize(date.format("dddd"))}
        </Row>
        <Row style={{ lineHeight: "16px", color: "#19181B", opacity: "0.4" }}>
          {date.format("D MMMM")}
        </Row>
      </Col>
    </Row>
  );
};

export default Time;
