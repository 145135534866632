/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedPlannedTaskList } from '../models/PaginatedPlannedTaskList';
import type { PaginatedTaskEmployeesList } from '../models/PaginatedTaskEmployeesList';
import type { PaginatedTaskList } from '../models/PaginatedTaskList';
import type { PatchedPlannedTask } from '../models/PatchedPlannedTask';
import type { PlannedTask } from '../models/PlannedTask';
import type { Task } from '../models/Task';
import type { TaskEmployees } from '../models/TaskEmployees';
import type { TaskEmployeesUnreadCount } from '../models/TaskEmployeesUnreadCount';
import type { TaskUpdate } from '../models/TaskUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TasksService {

    /**
     * Список задач
     * @returns PaginatedTaskList
     * @throws ApiError
     */
    public static tasksList({
        limit,
        objectId,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        objectId?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedTaskList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks',
            query: {
                'limit': limit,
                'object_id': objectId,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Создать задачу с назначением исполнителей
     * @returns Task
     * @throws ApiError
     */
    public static tasksCreate({
        requestBody,
    }: {
        requestBody: Task,
    }): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Task
     * @throws ApiError
     */
    public static tasksRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Task> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static tasksDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tasks/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Список исполнителей задачи
     * Сотрудники задачи
     * @returns PaginatedTaskEmployeesList
     * @throws ApiError
     */
    public static tasksEmployeesList({
        id,
        employeeId,
        limit,
        offset,
        ordering,
        search,
    }: {
        id: number,
        employeeId?: number,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedTaskEmployeesList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/{id}/employees',
            path: {
                'id': id,
            },
            query: {
                'employee_id': employeeId,
                'limit': limit,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Назначение исполнителя задачи
     * Сотрудники задачи
     * @returns TaskEmployees
     * @throws ApiError
     */
    public static tasksEmployeesCreate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: TaskEmployees,
    }): CancelablePromise<TaskEmployees> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks/{id}/employees',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Обновление задачи
     * @returns TaskUpdate
     * @throws ApiError
     */
    public static tasksUpdateUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: TaskUpdate,
    }): CancelablePromise<TaskUpdate> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tasks/{id}/update',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Обновление исполнителя задачи
     * Сотрудники задачи
     * @returns TaskEmployees
     * @throws ApiError
     */
    public static tasksEmployeeUpdate({
        taskEmployeeId,
        requestBody,
    }: {
        taskEmployeeId: number,
        requestBody: TaskEmployees,
    }): CancelablePromise<TaskEmployees> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/tasks/employee/{task_employee_id}',
            path: {
                'task_employee_id': taskEmployeeId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Сотрудники задачи
     * @returns void
     * @throws ApiError
     */
    public static tasksEmployeeDestroy({
        taskEmployeeId,
    }: {
        taskEmployeeId: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tasks/employee/{task_employee_id}',
            path: {
                'task_employee_id': taskEmployeeId,
            },
        });
    }

    /**
     * Ознакомление исполнителя с задачей по TaskEmployee.id
     * Сотрудники задачи
     * @returns any No response body
     * @throws ApiError
     */
    public static tasksEmployeeReadCreate({
        taskEmployeeId,
    }: {
        taskEmployeeId: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks/employee/{task_employee_id}/read',
            path: {
                'task_employee_id': taskEmployeeId,
            },
        });
    }

    /**
     * Кол-во непрочитанных задач
     * Возвращает кол-во непрочитанных задач,
     * для сотрудника который делает запрос
     * @returns TaskEmployeesUnreadCount
     * @throws ApiError
     */
    public static tasksEmployeeUnreadCountRetrieve(): CancelablePromise<TaskEmployeesUnreadCount> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/employee/unread_count',
        });
    }

    /**
     * Список плановых задач
     * @returns PaginatedPlannedTaskList
     * @throws ApiError
     */
    public static tasksPlannedList({
        limit,
        objectId,
        offset,
        ordering,
        search,
    }: {
        /**
         * Number of results to return per page.
         */
        limit?: number,
        objectId?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedPlannedTaskList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/planned',
            query: {
                'limit': limit,
                'object_id': objectId,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * Создать плановую задачу
     *
     * task_employees выбираем из поста
     * weekdays - это массив из 7 целых чисел, где 0 - воскресенье, 6 - суббота, и т.д.
     *
     * @returns PlannedTask
     * @throws ApiError
     */
    public static tasksPlannedCreate({
        requestBody,
    }: {
        requestBody: PlannedTask,
    }): CancelablePromise<PlannedTask> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/tasks/planned',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Получить плановую задачу по id
     * @returns PlannedTask
     * @throws ApiError
     */
    public static tasksPlannedRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<PlannedTask> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/tasks/planned/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Обновить плановую задачу
     * @returns PlannedTask
     * @throws ApiError
     */
    public static tasksPlannedPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedPlannedTask,
    }): CancelablePromise<PlannedTask> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/tasks/planned/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static tasksPlannedDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/tasks/planned/{id}',
            path: {
                'id': id,
            },
        });
    }

}
