/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Act } from '../models/Act';
import type { PaginatedActList } from '../models/PaginatedActList';
import type { PatchedAct } from '../models/PatchedAct';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ActsService {

    /**
     * список актов
     * @returns PaginatedActList
     * @throws ApiError
     */
    public static actsList({
        dateFrom,
        dateTo,
        limit,
        object,
        offset,
        ordering,
        search,
    }: {
        /**
         * from
         */
        dateFrom?: string,
        /**
         * to
         */
        dateTo?: string,
        /**
         * Number of results to return per page.
         */
        limit?: number,
        object?: number,
        /**
         * The initial index from which to return the results.
         */
        offset?: number,
        /**
         * Which field to use when ordering the results.
         */
        ordering?: string,
        /**
         * A search term.
         */
        search?: string,
    }): CancelablePromise<PaginatedActList> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/acts/',
            query: {
                'date_from': dateFrom,
                'date_to': dateTo,
                'limit': limit,
                'object': object,
                'offset': offset,
                'ordering': ordering,
                'search': search,
            },
        });
    }

    /**
     * создание акта
     * @returns Act
     * @throws ApiError
     */
    public static actsCreate({
        requestBody,
    }: {
        requestBody: Act,
    }): CancelablePromise<Act> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/acts/',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * получение акта
     * @returns Act
     * @throws ApiError
     */
    public static actsRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<Act> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/acts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * обновление акта
     * @returns Act
     * @throws ApiError
     */
    public static actsUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody: Act,
    }): CancelablePromise<Act> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/acts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * частичное обновление акта
     * @returns Act
     * @throws ApiError
     */
    public static actsPartialUpdate({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PatchedAct,
    }): CancelablePromise<Act> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/acts/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static actsDestroy({
        id,
    }: {
        id: number,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/acts/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * скачать акт в формате docx
     * @returns any No response body
     * @throws ApiError
     */
    public static actsDocxRetrieve({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/acts/{id}/docx',
            path: {
                'id': id,
            },
        });
    }

}
