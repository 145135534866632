/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomRefreshToken } from '../models/CustomRefreshToken';
import type { Login } from '../models/Login';
import type { ResponseLogin } from '../models/ResponseLogin';
import type { TokenVerify } from '../models/TokenVerify';
import type { UserEmployeeProfile } from '../models/UserEmployeeProfile';
import type { UserProfile } from '../models/UserProfile';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Takes a set of user credentials and returns an access and refresh JSON web
     * token pair to prove the authentication of those credentials.
     * @returns ResponseLogin
     * @throws ApiError
     */
    public static userLoginCreate({
        requestBody,
    }: {
        requestBody: Login,
    }): CancelablePromise<ResponseLogin> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any No response body
     * @throws ApiError
     */
    public static userLogoutRetrieve(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/logout',
        });
    }

    /**
     * @returns UserEmployeeProfile
     * @returns UserProfile
     * @throws ApiError
     */
    public static userProfileRetrieve(): CancelablePromise<UserEmployeeProfile | UserProfile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/profile',
        });
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web
     * token if the refresh token is valid.
     * @returns CustomRefreshToken
     * @throws ApiError
     */
    public static userRefreshTokenCreate({
        requestBody,
    }: {
        requestBody: CustomRefreshToken,
    }): CancelablePromise<CustomRefreshToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/refresh-token',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Takes a token and indicates if it is valid.  This view provides no
     * information about a token's fitness for a particular use.
     * @returns TokenVerify
     * @throws ApiError
     */
    public static userTokenVerifyCreate({
        requestBody,
    }: {
        requestBody: TokenVerify,
    }): CancelablePromise<TokenVerify> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/token-verify',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
