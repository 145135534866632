import { action, makeObservable, observable, override } from "mobx";

import { OpenAPI } from "api";
import { request } from "api/core/request";
import { Objects } from "api/models/Objects";
import { ObjectsService } from "api/services/ObjectsService";
import { BaseApiService } from "core/services/BaseApiService";
import { BaseCrudStore } from "core/stores/BaseCrudStore";
import { Employees, EmployeesService } from "api";
import { IFiltersProps, IPaginatedProps } from "core/types";
import { EventTypeEnum } from "modules/Events";

interface IFilters extends IFiltersProps, IPaginatedProps {
  isGuarded?: string;
}

export class ObjectStore extends BaseCrudStore<
  Objects,
  Objects,
  Objects,
  IFilters
> {
  @observable employees: Employees[] = [];
  @observable isEmployeesLoading: boolean = false;
  @observable selectedObject: Objects | null = null;

  constructor(points?: any) {
    super(
      new BaseApiService({
        get: ObjectsService.objectsRetrieve,
        getAll: ObjectsService.objectsShortList,
        create: ObjectsService.objectsCreate,
        update: ObjectsService.objectsUpdate,
        delete: ObjectsService.objectsDestroy,
        ...points,
      })
    );

    makeObservable(this);
  }

  @override
  create = async (object: Objects) => {
    const createdObj = await request<Objects>(OpenAPI, {
      formData: object,
      method: "POST",
      url: "/api/v1/objects",
    });

    return createdObj;
  };

  @override
  update = async (formData: Objects) => {
    const updatedObj = await request<Objects>(OpenAPI, {
      method: "PUT",
      url: "/api/v1/objects/{id}",
      path: {
        id: formData.id,
      },
      formData: formData,
    });

    if (!updatedObj) {
      return;
    }

    const storeEntity = this.entities.find((x) => x.id === updatedObj.id);

    if (storeEntity) {
      Object.assign(storeEntity, updatedObj);
    }

    Object.assign(this.entity!, updatedObj);

    return updatedObj;
  };

  @action
  selectObject(object: Objects | null) {
    this.selectedObject = object;
    this.employees = [];
  }

  @action
  setAvatar(photo: Objects["photo"]) {
    if (this.entity) {
      this.entity = { ...this.entity, photo };
    }
  }

  @action
  setColor(color: Objects["color"]) {
    if (this.entity) {
      this.entity = { ...this.entity, color: color };
    }
  }

  @action
  changeNewsCount(objectId: Objects["id"], type: EventTypeEnum, count: number) {
    const object = this.entities.find((x) => x.id === objectId);

    if (!object) {
      return;
    }

    switch (type) {
      case EventTypeEnum.ALARM:
        // @ts-ignore
        object.news.alarms += count;
        break;
      case EventTypeEnum.REPORT:
      case EventTypeEnum.PROMPT_REPORT:
        // @ts-ignore
        object.news.prompt_reports += count;
        break;
      case EventTypeEnum.TASK_REPORT:
        // @ts-ignore
        object.news.reports += count;
        break;
      case EventTypeEnum.INFO:
        // @ts-ignore
        object.news.infos += count;
        break;
      case EventTypeEnum.MESSAGE:
        // @ts-ignore
        object.news.messages += count;
        break;
      case EventTypeEnum.ACT:
        // @ts-ignore
        object.news.acts += count;
        break;
    }

    // TODO: fix reactivity
    this.entities = [...this.entities];
  }

  @action
  loadEmployees = async () => {
    this.isEmployeesLoading = true;
    const { results } = await EmployeesService.employeesList({
      objectId: this.entity?.id,
    });
    this.employees = results || [];
    this.isEmployeesLoading = false;
  };
}

export type ObjectStoreType = ObjectStore;
